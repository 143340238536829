import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_class_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("クラス管理 -クラス登録-")]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.postData ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _vm._e(), _c('form', [_c('row-item', {
    staticClass: "common_form"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("学年")]), _c('div', {
    staticClass: "col_9"
  }, [_c('row-item', {
    staticClass: "wrap"
  }, _vm._l(_vm.grades, function (grade) {
    return _c('div', {
      key: grade.id,
      staticClass: "col_2 radio"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.classroomGrades,
        expression: "classroomGrades"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'grade_' + grade.id,
        "type": "checkbox",
        "disabled": _vm.disableCheckbox(grade.id)
      },
      domProps: {
        "value": grade.id,
        "checked": Array.isArray(_vm.classroomGrades) ? _vm._i(_vm.classroomGrades, grade.id) > -1 : _vm.classroomGrades
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.classroomGrades,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = grade.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.classroomGrades = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.classroomGrades = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.classroomGrades = $$c;
          }
        }
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'grade_' + grade.id
      }
    }, [_vm._v(_vm._s(grade.name))])]);
  }), 0)], 1), _vm.error.grade ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.grade) + " ")]) : _vm._e()]), _c('row-item', {
    staticClass: "common_form"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("学年組")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.classroom.name,
      expression: "classroom.name"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "type": "text",
      "placeholder": "例）１年１組、さくら組、2年難聴クラスなど"
    },
    domProps: {
      "value": _vm.classroom.name
    },
    on: {
      "change": _vm.handleChange,
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.classroom, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e(), _c('p', {
    staticClass: "note_text"
  }, [_vm._v(" ※こちらのテキストボックスに入力いただいた学年組の表記が、"), _c('br'), _vm._v(" COCOOの生徒名簿や全校出欠席一覧などにて表示されます。"), _c('br'), _vm._v(" 複式学級・特別支援級などのクラス名にも対応しています。 ")])])]), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'classrooms'
      }
    }
  }, [_vm._v(" キャンセル ")]), _vm.$route.params.id ? _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "更新する"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveData.apply(null, arguments);
      }
    }
  }) : _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveData.apply(null, arguments);
      }
    }
  }), _vm.$route.params.id ? _c('button', {
    staticClass: "delete_button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmDelete.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除する ")]) : _vm._e()], 1)], 1), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.completeCreate
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.removeData,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };