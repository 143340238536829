import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_questionnaire_data content"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("アンケート詳細 - 質問内容")]), _c('page-switch', {
    attrs: {
      "message-id": _vm.enquete.message_id,
      "active": "detail"
    }
  }), _vm.isLoading ? _c('content-placeholders', [_c('div', {
    staticClass: "questionnaire_table"
  }, [_c('div', {
    staticClass: "questionnaire_table__content"
  }, [_c('row-item', [_c('p', {
    staticClass: "col_12"
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)]), _c('row-item', [_c('p', {
    staticClass: "col_12"
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)])], 1)])]) : _c('div', {
    staticClass: "questionnaire_table"
  }, [_c('div', {
    staticClass: "questionnaire_table__content"
  }, [_c('row-item', [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("アンケートの名前")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.enquete.subject) + " ")])]), _c('row-item', [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("回答期限")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.getDateJP(_vm.enquete.implement_to)) + " " + _vm._s(_vm.isOpen ? "(実施中)" : "(受付終了)") + " ")])]), _c('row-item', [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("作成者")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.enquete.teacher ? _vm.enquete.teacher.name + " 先生" : "（削除済みの先生）") + " ")])]), _c('row-item', [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("対象人数")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + ": " + _vm._s(_vm.statuses.length) + "人, 関係者: " + _vm._s(_vm.participantStatuses.length) + "人 ")])]), _c('row-item', [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("共有範囲")]), _vm.enquete.visibility === 'limited' ? _c('p', {
    staticClass: "col_9"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/keylock.svg"),
      "alt": "keylock",
      "width": "14px"
    }
  }), _vm._v(" 限定共有（管理者権限・作成者の先生のみ） ")]) : _c('p', {
    staticClass: "col_9"
  }, [_vm._v("学内共有（校内すべての先生へ共有）")])])], 1)]), _vm.isLoading ? _c('content-placeholders', [_c('div', {
    staticClass: "questionnaire_table"
  }, [_c('div', {
    staticClass: "questionnaire_table__content"
  }, [_c('row-item', [_c('p', {
    staticClass: "fake_scroll_table__span col_12"
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)]), _vm._l(3, function (n) {
    return _c('row-item', {
      key: n
    }, [_c('p', {
      staticClass: "col_12"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)]);
  })], 2)])]) : _vm._l(_vm.enquete.questions, function (question, index) {
    return _c('div', {
      key: index,
      staticClass: "questionnaire_table"
    }, [_c('div', {
      staticClass: "questionnaire_table__content"
    }, [_c('row-item', [_c('p', {
      staticClass: "col_3"
    }, [_vm._v("質問" + _vm._s(index + 1))]), _c('p', {
      staticClass: "col_9"
    }, [_vm._v(" " + _vm._s(question.subject) + " ")])]), question.type === 'text' ? [_c('row-item', {
      staticClass: "item_wrapper"
    }, [_c('p', {
      staticClass: "col_3"
    }, [_vm._v("回答")]), _c('p', {
      staticClass: "col_9"
    }, [_vm._v(" (自由記述形式で回答されます。この質問への電話による回答はできません。) ")])])] : _vm._l(question.options, function (option, index2) {
      return _c('row-item', {
        key: index2
      }, [_c('p', {
        staticClass: "col_3"
      }, [_vm._v("回答" + _vm._s(index2 + 1))]), _c('p', {
        staticClass: "col_9"
      }, [_vm._v(" " + _vm._s(option) + " ")])]);
    })], 2)]);
  }), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'enquetes'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };