import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    class: 'new_information ' + (_vm.me.classroom && _vm.me.classroom.length === 0 ? 'expand' : '')
  }, [_c('div', {
    staticClass: "col2_frame__inner"
  }, [_c(_setup.RowItem, {
    staticClass: "between heading"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("最新の欠席連絡")]), _c('router-link', {
    staticClass: "show_button",
    attrs: {
      "to": {
        name: 'attendances'
      }
    }
  }, [_vm._v(" 一覧 ")])], 1), _c('div', {
    staticClass: "inner"
  }, [_vm.isLoading ? _c('content-placeholders', _vm._l(10, function (n) {
    return _c('content-placeholders-text', {
      key: n,
      attrs: {
        "lines": 1
      }
    });
  }), 1) : _c('div', _vm._l(_vm.attendancesMod, function (attendance, i) {
    return _c('div', {
      key: i,
      staticClass: "list"
    }, [_c('div', {
      staticClass: "data_wrap"
    }, [_c('div', {
      staticClass: "data_item",
      class: {
        deleted: !attendance.is_daily_latest
      }
    }, [attendance.isHistory ? _c('img', {
      attrs: {
        "src": require("@/assets/images/history.svg"),
        "alt": "history",
        "width": "5%"
      }
    }) : _c('p', {
      staticClass: "date"
    }, [_vm._v(" " + _vm._s(_vm._f("mmdd")(attendance.date)) + " ")]), _c('p', {
      class: "cause icon--".concat(_setup.iconColor(attendance.type))
    }, [attendance.is_daily_latest ? _c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.edit',
          params: {
            id: attendance.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("attendanceShort")(attendance.type)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("attendanceShort")(attendance.type)) + " ")])], 1), _vm._l(attendance.absents, function (count, i2) {
      return _c('div', {
        key: i2
      }, [attendance.absents.length > 0 ? _c('p', {
        staticClass: "icon--date"
      }, [_c('span', {
        staticClass: "num"
      }, [_vm._v(_vm._s(count))]), _vm._v("欠 ")]) : _vm._e()]);
    }), _c('p', {
      staticClass: "class"
    }, [_vm._v(" " + _vm._s(attendance.classroom_name) + " ")]), _c('p', {
      staticClass: "name"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.detail',
          query: {
            student_id: attendance.student_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("trim")(attendance.student ? attendance.student.name : "")) + " ")])], 1), _c('time', {
      staticClass: "date"
    }, [_vm._v("(" + _vm._s(attendance.created_at) + "連絡)")])], 2), _c('p', {
      staticClass: "reason"
    }, [_vm._v(" " + _vm._s(attendance.guardian_memo) + " " + _vm._s(attendance.comment ? "先生用メモ:" + attendance.comment : "") + " ")])])]);
  }), 0)], 1)], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };