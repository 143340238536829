import "core-js/modules/es.string.trim.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c(_setup.ConfirmSlotModal, {
    attrs: {
      "click-ok": _setup.moveGeneratePage,
      "click-cancel": _vm.clickCancel
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" 関係者用 QRコード表示 ")];
      },
      proxy: true
    }])
  }, [_c('p', [_vm._v("表示されたページをブラウザの印刷機能を利用して印刷してください。")]), _c('p', {
    staticClass: "confirm_text"
  }, [_vm._v(" 有効期限は "), _c('span', {
    staticClass: "alert_text"
  }, [_vm._v(_vm._s(_setup.moment().add(3, "month").format("YYYY年MM月DD日")))]), _vm._v(" です。 ")]), _c('br'), _c(_setup.RowItem, [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "belong"
    }
  }, [_vm._v("所属")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _setup.belong,
      expression: "belong",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "id": "belong",
      "type": "text",
      "placeholder": "所属を入力 （例 PTA、教職員 など）"
    },
    domProps: {
      "value": _setup.belong
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _setup.belong = $event.target.value.trim();
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('p', [_vm._v("お知らせ配信では宛先を選択する際、表示されます。")]), _c('p', [_vm._v("※グループ登録は別途必要です。")]), _c('br'), _c(_setup.RowItem, [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "people_number"
    }
  }, [_vm._v("発行枚数")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.number,
      expression: "number"
    }],
    staticClass: "common_form--input col_1",
    attrs: {
      "id": "people_number",
      "type": "number",
      "min": "1",
      "max": "100"
    },
    domProps: {
      "value": _setup.number
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _setup.number = $event.target.value;
      }
    }
  }), _vm._v("枚 ")]), _c('p', [_vm._v("関係者用QRコードは1人1枚の発行が必要です。")]), _c('p', {
    staticClass: "confirm_text"
  }, [_c('span', {
    staticClass: "alert_text"
  }, [_vm._v("一度登録されたQRコードは無効となります。")])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };