import moment from "moment";
export default {
  __name: 'GovForumArticle',
  props: {
    forum: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    // @ts-check
    return {
      __sfc: true,
      moment: moment
    };
  }
};