import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_group_index"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("グループ管理 - グループ一覧")]), _c('form', {
    staticClass: "gray_frame",
    attrs: {
      "action": "",
      "method": "get"
    }
  }, [_c(_setup.RowItem, [_c('div', {
    staticClass: "col_5"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_3",
    attrs: {
      "for": "filter_keyword"
    }
  }, [_vm._v("グループ名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.keyword,
      expression: "filter.keyword"
    }],
    staticClass: "common_form--input col_10",
    attrs: {
      "id": "filter_keyword",
      "type": "text",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.filter.keyword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.filter, "keyword", $event.target.value);
      }
    }
  })])])], 1), _c('div', {
    staticClass: "col_3"
  }, [_c(_setup.RowItem, [_c('div', {
    staticClass: "checkbox col_6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showParticipantGroup,
      expression: "showParticipantGroup"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "showParticipantGroup",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.showParticipantGroup) ? _vm._i(_vm.showParticipantGroup, null) > -1 : _vm.showParticipantGroup
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.showParticipantGroup,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.showParticipantGroup = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.showParticipantGroup = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.showParticipantGroup = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "showParticipantGroup"
    }
  }, [_vm._v("関係者")])]), _c('div', {
    staticClass: "checkbox col_6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showStudentGroup,
      expression: "showStudentGroup"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "showStudentGroup",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.showStudentGroup) ? _vm._i(_vm.showStudentGroup, null) > -1 : _vm.showStudentGroup
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.showStudentGroup,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.showStudentGroup = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.showStudentGroup = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.showStudentGroup = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "showStudentGroup"
    }
  }, [_vm._v("生徒")])])])], 1), _c('div', {
    staticClass: "col_3"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_3",
    attrs: {
      "for": "filter_classroom"
    }
  }, [_vm._v("クラス")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.classroom,
      expression: "filter.classroom"
    }],
    staticClass: "common_form--select col_8",
    attrs: {
      "id": "filter_classroom",
      "name": "classroom"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filter, "classroom", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("すべて")]), _vm._l(_setup.classrooms, function (classroom, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": classroom.id
      }
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  })], 2)])], 1), _c('div', {
    staticClass: "col_1 tar"
  }, [_c('input', {
    staticClass: "search_button",
    attrs: {
      "type": "submit",
      "value": "検索"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.filterList.apply(null, arguments);
      }
    }
  })])])], 1), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'groups.create'
      }
    }
  }, [_vm._v(" 新規登録 ")])], 1), _c('div', {
    staticClass: "groups_table"
  }, [_c(_setup.RowItem, {
    staticClass: "groups_table__head"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("分類")]), _c('p', {
    staticClass: "col_5"
  }, [_vm._v("グループ名")]), _c('p', {
    staticClass: "col_5"
  }, [_vm._v("人数")])]), _c('div', {
    staticClass: "groups_table__body"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filteredStudentGroups.length > 0,
      expression: "filteredStudentGroups.length > 0"
    }]
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_12"
  }, [_vm._v("全校")])]), _vm._l(_vm.filteredStudentGroups, function (group, i) {
    return _c(_setup.RowItem, {
      key: i
    }, [_c('p', {
      staticClass: "col_2"
    }), _c('p', {
      staticClass: "col_5"
    }, [_vm._v(" " + _vm._s(group.name) + " ")]), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(group.student ? group.student.length + "名" : "") + " ")]), _c('div', {
      staticClass: "col_3 tar"
    }, [_c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: 'groups.edit',
          params: {
            id: group.id
          }
        }
      }
    }, [_vm._v(" 編集 ")])], 1)]);
  })], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filteredParticipantGroups.length > 0,
      expression: "filteredParticipantGroups.length > 0"
    }]
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_12"
  }, [_vm._v("関係者")])]), _vm._l(_vm.filteredParticipantGroups, function (group, i) {
    return _c(_setup.RowItem, {
      key: i
    }, [_c('p', {
      staticClass: "col_2"
    }), _c('p', {
      staticClass: "col_5"
    }, [_vm._v(" " + _vm._s(group.name) + " ")]), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(group.participant ? group.participant.length + "名" : "") + " ")]), _c('div', {
      staticClass: "col_3 tar"
    }, [_c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: 'groups.edit',
          params: {
            id: group.id
          }
        }
      }
    }, [_vm._v(" 編集 ")])], 1)]);
  })], 2), _vm._l(_vm.filteredClassroomGroups, function (classroom, i) {
    return _c('div', {
      key: i
    }, [_c(_setup.RowItem, [_c('p', {
      staticClass: "col_12"
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")])]), _vm._l(classroom.group, function (group, i2) {
      return _c(_setup.RowItem, {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: group,
          expression: "group"
        }],
        key: i2
      }, [_c('p', {
        staticClass: "col_2"
      }), _c('p', {
        staticClass: "col_5"
      }, [_vm._v(" " + _vm._s(group.name) + " ")]), _c('p', {
        staticClass: "col_2"
      }, [_vm._v(" " + _vm._s(group.student ? group.student.length + "名" : "") + " ")]), _c('div', {
        staticClass: "col_3 tar"
      }, [_c('router-link', {
        staticClass: "edit_button",
        attrs: {
          "to": {
            name: 'groups.edit',
            params: {
              id: group.id
            }
          }
        }
      }, [_vm._v(" 編集 ")])], 1)]);
    })], 2);
  })], 2)], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };