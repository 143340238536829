import store from "./services/store.js";
import axios from "axios";
export default {
  store: store,
  name: "App",
  created: function created() {
    var _this = this;

    axios.get("/news.json").then(function (response) {
      _this.$store.dispatch("commitNews", response.data);
    }).catch(function (error) {
      console.log(error);
    });
  },
  methods: {}
};