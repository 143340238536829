import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_information_data status"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("お知らせ配信履歴詳細")]), _vm.isLoading ? _c(_setup.LoadingMessage) : _vm._e(), _c('section', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "switch_button__left item active",
    attrs: {
      "to": {
        name: 'messages.detail',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 送信内容 ")]), _c('router-link', {
    staticClass: "switch_button__right item",
    attrs: {
      "to": {
        name: 'messages.status',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 配信状況 ")])], 1), _c('section', {
    staticClass: "preview_area"
  }, [_vm._m(0), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("配信先")]), _c('p', {
    staticClass: "col_7"
  }, [_vm._v(" " + _vm._s(_vm.message_targets_count) + "人（ " + _vm._s(_vm.studentAddress) + _vm._s(_vm.message_targets_students_count) + " 人・関係者" + _vm._s(_vm.message_targets_participants_count) + "人） " + _vm._s(_vm.message_detail.teacher ? _vm.message_detail.teacher.name + " 先生作成" : "") + " ")]), _c('div', {
    staticClass: "col_2 tar"
  }, [_c('router-link', {
    staticClass: "confirm_button",
    attrs: {
      "to": {
        name: 'messages.status',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 配信状況確認 ")])], 1)]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("作成者")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.teacher ? _vm.message_detail.teacher.name + " 先生" : "（削除済みの先生）") + " ")])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("共有範囲")]), _vm.message_detail.message.visibility === 'default' ? [_c('p', {
    staticClass: "col_7"
  }, [_vm._v("学内共有（校内すべての先生へ共有）")]), _c('div', {
    staticClass: "col_2 tar"
  }, [_vm.message_detail.is_switch_visibility ? _c('button', {
    staticClass: "confirm_button",
    on: {
      "click": function click($event) {
        return _vm.switchVisibility('limited');
      }
    }
  }, [_vm._v(" 限定共有にする ")]) : _vm._e()])] : [_c('p', {
    staticClass: "col_8"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/keylock.svg"),
      "alt": "keylock",
      "width": "14px"
    }
  }), _vm._v(" 限定共有（管理者権限・作成者の先生のみ） ")]), _c('div', {
    staticClass: "col_2 tar"
  }, [_c('button', {
    staticClass: "confirm_button",
    on: {
      "click": function click($event) {
        return _vm.switchVisibility('default');
      }
    }
  }, [_vm._v(" 学内共有にする ")])])]], 2)], 1), _c('section', {
    staticClass: "preview_area"
  }, [_vm._m(1), _c(_setup.RowItem, {
    staticClass: "fake_scroll_table__tr"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("件名")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.content.subject) + " ")])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("配信日時")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.message.message_scheduled_for) + " ")])]), _c(_setup.RowItem, [_c('div', {
    staticClass: "col_12"
  }, [_c('label', {
    attrs: {
      "for": "message_subject"
    }
  }), _vm.message_detail.content.mail_body ? _c('div', {
    staticClass: "preview_input",
    attrs: {
      "id": "message_subject"
    }
  }, _vm._l(_vm.message_detail.content.mail_body.split('\n'), function (text, i) {
    return _c('p', {
      key: "".concat(i, "-").concat(_vm.message_detail.id)
    }, [_c(_setup.UrlToLink, {
      attrs: {
        "text": text
      }
    })], 1);
  }), 0) : _vm._e()])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("添付ファイル")]), _c('div', {
    staticClass: "col_9"
  }, [_vm.message_detail.message.attached_file ? _c('button', {
    staticClass: "file_button",
    attrs: {
      "disabled": _vm.getS3UrlStatusRunning
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.getS3SignedUrl.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getS3UrlStatus) + " ")]) : _c('span', [_vm._v("なし")])])])], 1), _c('section', {
    staticClass: "preview_area"
  }, [_vm._m(2), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("発信予約時間")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.message.call_scheduled_for) + " ")])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("自動再配信")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_call_setting) + " ")])]), _c(_setup.RowItem, [_c('div', {
    staticClass: "col_12"
  }, [_c('label', {
    attrs: {
      "for": "call_body"
    }
  }), _c('div', {
    staticClass: "preview_input",
    attrs: {
      "id": "call_body"
    }
  }, [_vm._v(" " + _vm._s(_vm.message_detail.content.call_body) + " ")])])])], 1), _vm.message_detail.enquete ? _c('section', {
    staticClass: "preview_area"
  }, [_vm._m(3), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("回答期限")]), _c('p', {
    staticClass: "col_9"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.enquete.implement_to) + " ")])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("アンケート名")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v(" " + _vm._s(_vm.message_detail.enquete_contents.subject) + " ")]), _c('p', {
    staticClass: "col_6"
  }, [_c('router-link', {
    staticClass: "confirm_button",
    attrs: {
      "to": {
        name: 'enquetes.result',
        params: {
          id: _vm.message_detail.enquete.id
        }
      }
    }
  }, [_vm._v(" アンケート状況確認 ")])], 1)])], 1) : _vm._e(), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "preview_area__description"
  }, [_c('p', {
    staticClass: "item"
  }, [_vm._v("基本情報")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "preview_area__description"
  }, [_c('p', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "icon_fontawesome"
  }, [_c('i', {
    staticClass: "far fa-envelope"
  })]), _vm._v("メール詳細 ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "preview_area__description"
  }, [_c('p', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "icon_fontawesome"
  }, [_c('i', {
    staticClass: "fas fa-phone"
  })]), _vm._v("電話詳細 ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "preview_area__description"
  }, [_c('p', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "icon_fontawesome"
  }, [_c('i', {
    staticClass: "fas fa-pencil-alt"
  })]), _vm._v("アンケート概要 ")])]);
}];
export { render, staticRenderFns };