import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_school_grade_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(" 学校管理 - 学年" + _vm._s(_vm.$route.params.id ? "編集" : "登録") + "- ")]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.postData ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _vm._e(), _c('form', {
    staticClass: "common_form"
  }, [_c('row-item', [_c('p', {
    staticClass: "required col_2"
  }, [_vm._v("学年")]), _c('div', {
    staticClass: "common_form__editor col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.grade.name,
      expression: "grade.name"
    }],
    staticClass: "common_form--input col_9",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.grade.name
    },
    on: {
      "change": _vm.handleChange,
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.grade, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'grades'
      }
    }
  }, [_vm._v(" キャンセル ")]), _vm.$route.params.id ? _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "更新する"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveData.apply(null, arguments);
      }
    }
  }) : _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveData.apply(null, arguments);
      }
    }
  }), _vm.$route.params.id ? _c('button', {
    staticClass: "delete_button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeData.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除 ")]) : _vm._e()], 1)], 1), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.completeCreate
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };