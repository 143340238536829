import _toConsumableArray from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import { getData, createData, updateData } from "@/services/axios";
import { deleteData } from "../../services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import ConfirmModal from "@/components/common/ConfirmModal";
import Encoding from "encoding-japanese";
import RowItem from "../RowItem";
export default {
  name: "CreateClassRoom",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage,
    ConfirmModal: ConfirmModal
  },
  data: function data() {
    return {
      isLoading: {
        getData: false,
        postData: false,
        deleteData: false
      },
      loadingMessage: "",
      classroom: {},
      classroomGrades: [],
      showModal: false,
      createSuccess: false,
      error: {
        grade: null,
        name: null
      },
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      studentGrades: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "classrooms"])), {}, {
    disableCheckbox: function disableCheckbox() {
      var _this = this;

      return function (gradeId) {
        return _this.studentGrades.some(function (v) {
          return v === gradeId;
        });
      };
    }
  }),
  created: function created() {
    if (this.$route.params.id) {
      this.setData();
    }
  },
  methods: {
    setData: function setData() {
      var _this2 = this;

      if (this.$route.params.id) {
        this.isLoading.getData = true;
        getData("classrooms/" + this.$route.params.id + "?with[]=grade&with[]=student").then(function (data) {
          if (data) {
            _this2.classroom = data.items[0];

            if (_this2.classroom.grade) {
              _this2.classroomGrades = _this2.classroom.grade.map(function (v) {
                return v.id;
              });
            }
          }

          var sutdentGradeId;

          for (var i in _this2.classroom.student) {
            if (_this2.classroom.student[i].grade_id !== sutdentGradeId) {
              for (var j in _this2.classroomGrades) {
                if (_this2.classroom.student[i].grade_id === _this2.classroomGrades[j]) {
                  sutdentGradeId = _this2.classroom.student[i].grade_id;

                  _this2.studentGrades.push(sutdentGradeId);
                }
              }
            }
          }
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this2.isLoading.getData = false;
        });
      }
    },
    handleChange: function handleChange(e) {
      this.classroom.name = Encoding.toHankakuCase(e.target.value).trim();
    },
    saveData: function saveData() {
      var _this3 = this;

      var valid = true;
      this.error = {
        name: null
      };

      if (this.classroomGrades.length <= 0) {
        this.error.grade = "学年を入力してください。";
        valid = false;
      }

      if (!this.classroom.name) {
        this.error.name = "クラス名を入力してください。";
        valid = false;
      }

      if (this.classrooms.find(function (v) {
        return v.id !== _this3.$route.params.id && v.name === _this3.classroom.name;
      })) {
        this.error.name = "既存のクラスと重複しています。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      this.classroom.name = Encoding.toHankakuCase(this.classroom.name).trim();
      this.isLoading.postData = true;

      if (this.$route.params.id) {
        this.loadingMessage = "クラスデータを更新しています。"; // 更新の際にはindexを変更しない

        updateData("writer/write_classroom/" + this.$route.params.id, {
          grades: this.classroomGrades,
          name: this.classroom.name
        }).then(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "クラス更新が完了しました",
            title: "更新完了"
          });

          _this3.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(function (data) {
            _this3.$store.dispatch("commitClassroom", data.items);

            _this3.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "更新に失敗しました。",
            title: "エラー"
          });

          _this3.showModal = true;
        }).then(function () {
          _this3.showModal = true;
          _this3.isLoading.postData = false;
        });
      } else {
        // classroom.indexでは本来複式学級の正しいindexを表現できず、DB設計が破綻している。やむをえず既存のindexの最大値を取る
        var targetIndex = Number.isInteger(Math.max.apply(Math, _toConsumableArray(this.grades.filter(function (v) {
          return _this3.classroomGrades.includes(v.id);
        }).map(function (v) {
          return Math.max.apply(Math, _toConsumableArray(v.classroom.map(function (c) {
            return c.index;
          })));
        })))) ? Math.max.apply(Math, _toConsumableArray(this.grades.filter(function (v) {
          return _this3.classroomGrades.includes(v.id);
        }).map(function (v) {
          return Math.max.apply(Math, _toConsumableArray(v.classroom.map(function (c) {
            return c.index;
          })));
        }))) : 1;
        this.loadingMessage = "クラスデータを登録しています。";
        createData("writer/write_classroom", {
          grades: this.classroomGrades,
          name: this.classroom.name,
          index: targetIndex
        }).then(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "クラス登録が完了しました",
            title: "登録完了"
          });

          _this3.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(function (data) {
            _this3.$store.dispatch("commitClassroom", data.items);

            _this3.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "作成に失敗しました。",
            title: "エラー"
          });

          _this3.showModal = true;
        }).then(function () {
          _this3.showModal = true;
          _this3.isLoading.postData = false;
        });
      }
    },
    confirmDelete: function confirmDelete() {
      this.confirmMessageTitle = "クラス削除";
      this.confirmMessage = "クラスを削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },
    removeData: function removeData() {
      var _this4 = this;

      if (this.$route.params.id) {
        deleteData("eraser/delete_classroom/" + this.$route.params.id).then(function () {
          _this4.$store.dispatch("commitModalMessage", {
            message: "クラス削除が完了しました",
            title: "削除完了"
          });

          _this4.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(function (data) {
            _this4.$store.dispatch("commitClassroom", data.items);

            _this4.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(function () {
          _this4.$store.dispatch("commitModalMessage", {
            message: "削除に失敗しました。クラスに生徒が存在する可能性があります。",
            title: "エラー"
          });

          _this4.showModal = true;
        }).then(function () {
          _this4.showModal = true;
          _this4.showConfirmModal = false;
        });
      }
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    },
    completeCreate: function completeCreate() {
      this.showModal = false;

      if (this.createSuccess) {
        this.$router.push({
          name: "classrooms"
        });
      }
    }
  }
};