import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('span', [_c(_setup.GroupClassSelectForm, {
    attrs: {
      "is-loading": false,
      "filter": _setup.filter
    },
    on: {
      "handleClassroomChange": _setup.handleClassroomChange,
      "handleGroupChange": _setup.handleGroupChange
    }
  }), _c('section', {
    staticClass: "calendar"
  }, [_c(_setup.CalendarHeading, {
    attrs: {
      "is-loading": false,
      "filter": _setup.filter
    },
    on: {
      "init": _setup.init
    }
  }), _c(_setup.AttendanceTemperatureSwitch, {
    attrs: {
      "filter": _setup.filter
    }
  }), _c(_setup.MonthlyTemperatureCalendar, {
    attrs: {
      "filter": _setup.filter
    }
  })], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };