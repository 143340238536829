import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import Vue from "vue";
import { updateData, getDataByPostMethod } from "@/services/axios";
import moment from "moment";
import VueQriously from "vue-qriously";
import { mapState } from "vuex";
Vue.use(VueQriously);
export default {
  name: "StudentsIndex",
  data: function data() {
    return {
      env: process.env,
      isLoading: true,
      showNotice: false,
      students: []
    };
  },
  head: {
    title: function title() {
      return {
        inner: "QR Code"
      };
    },
    meta: []
  },
  computed: _objectSpread({}, mapState(["studentAddress"])),
  created: function created() {
    if (this.$route.params.student_id) {
      this.setData();
    } else {
      this.isLoading = false;
      this.showNotice = true;
    }
  },
  methods: {
    setData: function setData() {
      var _this = this;

      updateData("writer/update_student_qrcode", {
        student_id: this.$route.params.student_id
      }).then(function () {
        return getDataByPostMethod("reader/get_students_qrcode", {
          student_id: _this.$route.params.student_id
        });
      }).then(function (data) {
        data.items.forEach(function (v) {
          v.expire_on = moment(v.gca_entry_key.expire_on).format("YYYY/MM/DD");

          _this.students.push(v);
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading = false;
      });
    },
    QRdata: function QRdata(student) {
      return process.env.VUE_APP_GUARDIAN_APP_URL + "/entry/" + student.gca_entry_key.key;
    }
  }
};