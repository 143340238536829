import "core-js/modules/es.array.concat.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    staticClass: "comment"
  }, [_setup.isLoading ? _c(_setup.LoadingMessage) : _vm._e(), _c('div', {
    staticClass: "comment__header"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("この記事へのコメント")]), _c('div', {
    staticClass: "count"
  }, [_c('i', {
    staticClass: "far fa-comment"
  }), _c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.commentList.length))])])]), _vm.commentList.length < 100 ? _c('div', {
    staticClass: "comment__input"
  }, [_c('contenteditable', {
    staticClass: "input",
    attrs: {
      "tag": "div",
      "no-nl": true,
      "no-html": true
    },
    model: {
      value: _setup.commentMessage,
      callback: function callback($$v) {
        _setup.commentMessage = $$v;
      },
      expression: "commentMessage"
    }
  }), !_setup.isUrlValidate ? _c('p', {
    staticClass: "warning"
  }, [_vm._v(" URLは1行に1つずつ記載してください。URLの前後に文字や記号、行内に複数のURLが含まれるとリンクが正しく機能しません。 ")]) : _vm._e(), _c('button', {
    staticClass: "submit",
    on: {
      "click": _setup.createForumComment
    }
  }, [_vm._v("コメント投稿")])], 1) : _vm._e(), _c('div', {
    staticClass: "comment__wrapper"
  }, _vm._l(_vm.commentList, function (comment) {
    return _c('article', {
      key: comment.id,
      staticClass: "comment__item",
      class: comment.status
    }, [_c('div', {
      staticClass: "comment__item__body"
    }, [_c('div', _vm._l(comment.message.split('\n'), function (text, i) {
      return _c('p', {
        key: "".concat(i, "-").concat(comment.id)
      }, [_c(_setup.UrlToLink, {
        attrs: {
          "text": text
        }
      })], 1);
    }), 0), comment.teacher_id === _setup.me.teacher_id && comment.status !== 'hidden' ? _c('button', {
      staticClass: "delete-button",
      attrs: {
        "value": comment.id
      },
      on: {
        "click": _setup.handleCommentDeleteButton
      }
    }, [_vm._v(" 削除 ")]) : _vm._e()]), _c('div', {
      staticClass: "comment__item__header"
    }, [_c('time', {
      staticClass: "date",
      attrs: {
        "datetime": comment.created_at
      }
    }, [_vm._v(" " + _vm._s(_setup.moment(comment.created_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), _c('p', {
      staticClass: "contributor"
    }, [_vm._v(_vm._s(comment.author_name) + "先生")])])]);
  }), 0), _setup.isShowDeleteConfirmModal ? _c(_setup.ConfirmSlotModal, {
    attrs: {
      "click-ok": _setup.deleteForumComment,
      "click-cancel": _setup.hideConfirmModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("コメント削除")];
      },
      proxy: true
    }], null, false, 2882734010)
  }, [_vm._v(" コメントを削除します。よろしいですか？ ")]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };