import { storeToRefs } from "pinia";
import { useAbsentAlertStore } from "@/stores/absentAlert";
import moment from "moment";
export default {
  __name: 'AbsentAlertList',
  setup: function setup(__props) {
    // @ts-check
    var absentAlertStore = useAbsentAlertStore();

    var _storeToRefs = storeToRefs(absentAlertStore),
        absentList3 = _storeToRefs.absentList3,
        absentList20 = _storeToRefs.absentList20,
        absentList30 = _storeToRefs.absentList30,
        latestDekitusByStudent = _storeToRefs.latestDekitusByStudent;

    return {
      __sfc: true,
      absentAlertStore: absentAlertStore,
      absentList3: absentList3,
      absentList20: absentList20,
      absentList30: absentList30,
      latestDekitusByStudent: latestDekitusByStudent,
      moment: moment
    };
  }
};