import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
// 定数
// URLと認定するパターン
var urlRegExp = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
export default {
  __name: 'UrlToLink',
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  setup: function setup(__props) {
    var props = __props; // @ts-check

    var trimText = props.text.trim();
    var isUrl = urlRegExp.test(trimText);
    return {
      __sfc: true,
      urlRegExp: urlRegExp,
      props: props,
      trimText: trimText,
      isUrl: isUrl
    };
  }
};