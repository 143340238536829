import { ref } from "vue";
import { useRoute } from "vue-router/composables";
import moment from "moment";
import GroupClassSelectForm from "@/components/attendances/monthly/GroupClassSelectForm.vue";
import CalendarHeading from "@/components/attendances/monthly/CalendarHeading.vue";
import MonthlyTemperatureCalendar from "@/components/temperatures/MonthlyTemperatureCalendar.vue";
import AttendanceTemperatureSwitch from "@/components/attendances/monthly/AttendanceTemperatureSwitch.vue";
export default {
  __name: 'MonthlyTemperature',
  setup: function setup(__props) {
    // @ts-check
    var route = useRoute();
    /**
     * 検索条件
     *
     * @type {Ref<{ classroomId: any; groupId: any; yearMonth: any }>} filter
     */

    var filter = ref({
      classroomId: 0,
      groupId: 0,
      yearMonth: null
    });

    var init = function init() {
      var _route$query$year_mon, _route$query$classroo, _route$query$group_id;

      filter.value.yearMonth = (_route$query$year_mon = route.query.year_month) !== null && _route$query$year_mon !== void 0 ? _route$query$year_mon : moment().format("YYYY-MM");
      filter.value.classroomId = (_route$query$classroo = route.query.classroom_id) !== null && _route$query$classroo !== void 0 ? _route$query$classroo : 0;
      filter.value.groupId = (_route$query$group_id = route.query.group_id) !== null && _route$query$group_id !== void 0 ? _route$query$group_id : 0;
    }; // クラスを変更した時はinitを経由せずに必要な部分だけ初期化


    var handleClassroomChange = function handleClassroomChange(classroomId) {
      filter.value.classroomId = classroomId;
    }; // グループを変更した時もinitを経由せずに必要な部分だけ初期化


    var handleGroupChange = function handleGroupChange(groupId) {
      filter.value.groupId = groupId;
      filter.value.classroomId = 0;
    }; // created


    init();
    return {
      __sfc: true,
      route: route,
      filter: filter,
      init: init,
      handleClassroomChange: handleClassroomChange,
      handleGroupChange: handleGroupChange,
      GroupClassSelectForm: GroupClassSelectForm,
      CalendarHeading: CalendarHeading,
      MonthlyTemperatureCalendar: MonthlyTemperatureCalendar,
      AttendanceTemperatureSwitch: AttendanceTemperatureSwitch
    };
  }
};