import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { createData, deleteData, getData, updateData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import MessageModal from "@/components/common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
import passwordValidator from "password-validator";
import { mapState } from "vuex";
var schema = new passwordValidator();
schema.is().min(8).is().max(100).has().uppercase().has().lowercase().has().digits().has().not().spaces();
export default {
  name: "EditTeacher",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage,
    MessageModal: MessageModal,
    ConfirmModal: ConfirmModal
  },
  data: function data() {
    return {
      showModal: false,
      isLoading: {
        classroom: true,
        teacher: true,
        classTeacher: true,
        postTeacher: false
      },
      loadingMessage: "",
      gradeClass: [],
      classrooms: [],
      teacher: {
        name: "",
        email: "",
        role_id: 12,
        use_mfa: false,
        account_lock: false
      },
      notifyRegistered: true,
      pageTitle: "先生登録",
      buttonLabel: "登録する",
      buttonDisabled: false,
      error: {
        name: null,
        email: null,
        classrooms: null
      },
      showConfirmModal: false,
      deleteActionType: "delete",
      confirmMessageTitle: "",
      confirmMessage: "",
      role_radio_options: [{
        id: 12,
        label: "一般教職員権限"
      }, {
        id: 11,
        label: "管理者権限"
      }]
    };
  },
  head: {
    title: function title() {
      return {
        inner: this.pageTitle
      };
    }
  },
  computed: _objectSpread({}, mapState(["me"])),
  created: function created() {
    if (this.$route.params.id) {
      this.pageTitle = "先生編集";
      this.buttonLabel = "更新する";
    }

    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("grades?with=classroom").then(function (data) {
        _this.gradeClass = data.items;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.classroom = false;
      });

      if (this.$route.params.id) {
        getData("reader/get_teachers_with_classrooms/" + this.$route.params.id).then(function (data) {
          _this.teacher = data.items[0];
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.teacher = false;
        });
        getData("teachers/classroom_teacher/" + this.$route.params.id).then(function (data) {
          data.items.forEach(function (e) {
            _this.classrooms.push(e.classroom_id);
          });
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.classTeacher = false;
        });
      } else {
        this.isLoading.teacher = false;
        this.isLoading.classTeacher = false;
      }
    },
    saveData: function saveData() {
      var _this2 = this;

      this.buttonDisabled = true; //        this.buttonLabel = 'おまちください'

      var valid = true;
      this.error = {
        name: null,
        email: null,
        classrooms: null
      };

      if (!this.teacher.name) {
        this.error.name = "名前を入力してください。";
        valid = false;
      } else {
        this.teacher.name = this.teacher.name.replace(/\s+/, " ");
      }

      if (!this.teacher.email) {
        this.error.email = "メールアドレスを入力してください。";
        valid = false;
      } else if (!emailValidator.validate(this.teacher.email)) {
        this.error.email = "メールアドレスの形式が正しくありません。";
        valid = false;
      }

      if (!valid) {
        this.buttonDisabled = false;
        return;
      }

      var payload = Object.assign(this.teacher, {
        classrooms: this.classrooms,
        notifyRegistered: !this.$route.params.id && this.notifyRegistered
      });
      this.isLoading.postTeacher = true;

      if (this.$route.params.id) {
        updateData("writer/write_teacher/" + this.$route.params.id, payload).then(function (result) {
          if (result.error_code) {
            switch (result.error_code) {
              case 404:
                _this2.$store.dispatch("commitModalMessage", {
                  message: "先生の更新に失敗しました (Auth0 user not found)",
                  title: "エラー"
                });

                break;

              default:
                _this2.$store.dispatch("commitModalMessage", {
                  message: "先生の更新に失敗しました (" + result.errors + ")",
                  title: "エラー"
                });

                break;
            }
          } else {
            _this2.$store.dispatch("commitModalMessage", {
              message: "先生更新が完了しました",
              title: "更新完了"
            });
          }

          console.log(result);
          return getData("teachers/whoami");
        }).then(function (data) {
          _this2.$store.dispatch("commitSelfProfile", data.items[0]);
        }).catch(function (error) {
          _this2.$store.dispatch("commitModalMessage", {
            message: "先生の更新に失敗しました",
            title: "エラー"
          });

          console.log(error);
        }).then(function () {
          _this2.isLoading.postTeacher = false;
          _this2.showModal = true;
          _this2.buttonDisabled = false;
        });
      } else {
        createData("writer/write_teacher", payload).then(function (result) {
          if (result.error_code === 409) {
            _this2.error.email = "このメールアドレスは既に使用されています";
            valid = false;
          } else if (result.code === "200") {
            _this2.$store.dispatch("commitModalMessage", {
              message: _this2.teacher.name + "先生は他校から異動してきました。以前の学校のパスワードでログインできます。",
              title: "異動完了"
            });

            _this2.showModal = true;
          } else {
            _this2.$store.dispatch("commitModalMessage", {
              message: "先生登録が完了しました",
              title: "登録完了"
            });

            _this2.showModal = true;
          }
        }).catch(function (error) {
          _this2.$store.dispatch("commitModalMessage", {
            message: "先生の更新に失敗しました",
            title: "エラー"
          });

          _this2.showModal = true;
          console.log(error);
        }).then(function () {
          _this2.isLoading.postTeacher = false;
          _this2.buttonDisabled = false;
        });
      }
    },
    confirmDelete: function confirmDelete() {
      this.confirmMessageTitle = "先生情報削除";
      this.confirmMessage = this.teacher.name + "先生を削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
      this.deleteActionType = "delete";
    },
    confirmTransfer: function confirmTransfer() {
      this.confirmMessageTitle = "先生異動";
      this.confirmMessage = this.teacher.name + "先生を異動します。他校へ異動した先生はこの学校にログインできなくなり、\n" + "新しい学校で再登録できるようになります。この先生の作成したメッセージやアンケートの作者は、\n" + "今操作をしている先生の名前に引き継がれます。本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
      this.deleteActionType = "transfer";
    },
    handleDeleteData: function handleDeleteData() {
      var _this3 = this;

      this.showConfirmModal = false;
      this.buttonDisabled = true;
      var actionText = "削除";
      var params = "";

      if (this.deleteActionType === "transfer") {
        actionText = "異動";
        params = "?transfer=1";
      }

      deleteData("teachers/" + this.$route.params.id + params).then(function (result) {
        if (result.error_code) {
          switch (result.error_code) {
            case 404:
              _this3.$store.dispatch("commitModalMessage", {
                message: "先生の" + actionText + "に失敗しました (Auth0 user not found)",
                title: "エラー"
              });

              break;

            default:
              _this3.$store.dispatch("commitModalMessage", {
                message: "先生の" + actionText + "に失敗しました (" + result.errors + ")",
                title: "エラー"
              });

              break;
          }
        } else {
          _this3.$store.dispatch("commitModalMessage", {
            message: "先生データを" + actionText + "しました",
            title: actionText + "完了"
          });
        }
      }).catch(function (error) {
        _this3.$store.dispatch("commitModalMessage", {
          message: "先生データの" + actionText + "に失敗しました",
          title: "エラー"
        });

        console.log(error);
      }).then(function () {
        _this3.showModal = true;
        _this3.buttonDisabled = false;
      });
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "teachers"
      });
    }
  }
};