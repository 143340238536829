import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import store from "../../services/store.js";
export default {
  store: store,
  name: "ConfirmModal",
  props: {
    confirmMessageTitle: {
      type: String,
      default: ""
    },
    confirmMessage: {
      type: String,
      default: ""
    },
    executeButtonLabel: {
      type: String,
      default: "OK"
    }
  },
  data: function data() {
    return {
      title: this.confirmMessageTitle,
      message: this.confirmMessage.replace(/\n/g, "<br/>")
    };
  }
};