var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_template_index"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("メッセージテンプレート")]), _c('form', {
    staticClass: "common_form gray_frame",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.listFilter.apply(null, arguments);
      }
    }
  }, [_c('row-item', [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "filterKeyword"
    }
  }, [_vm._v("件名検索")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.keyword,
      expression: "filterOptions.keyword"
    }],
    staticClass: "common_form--input col_7",
    attrs: {
      "id": "filterKeyword",
      "type": "text",
      "disabled": _vm.isLoading
    },
    domProps: {
      "value": _vm.filterOptions.keyword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.filterOptions, "keyword", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "col_3 tar"
  }, [_c('input', {
    staticClass: "search_button",
    attrs: {
      "type": "submit",
      "value": "検索",
      "disabled": _vm.isLoading
    }
  })])])], 1), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'messages.templates.create'
      }
    }
  }, [_vm._v(" テンプレートを作成する ")])], 1), _c('div', {
    staticClass: "messages_table"
  }, [_c('row-item', {
    staticClass: "messages_table__head"
  }, [_c('p', {
    staticClass: "col_10"
  }, [_vm._v("テンプレート名")]), _c('p', {
    staticClass: "col_2"
  })]), _vm.isLoading ? _c('div', {
    staticClass: "messages_table__body"
  }, _vm._l(5, function (n) {
    return _c('row-item', {
      key: n
    }, [_c('div', {
      staticClass: "col_12"
    }, [_c('content-placeholders', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1)]);
  }), 1) : !_vm.isLoading && _vm.filteredTemplates.length > 0 ? _c('div', {
    staticClass: "messages_table__body"
  }, _vm._l(_vm.filteredTemplates, function (template, index) {
    return _c('div', {
      key: index
    }, [_c('row-item', {
      class: {
        is_emergency: template.is_emergency
      }
    }, [_c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(_vm.getTitle(template)) + " ")]), _c('p', {
      staticClass: "col_2 tar"
    }, [_c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: 'messages.templates.edit',
          params: {
            id: template.id
          }
        }
      }
    }, [_vm._v(" 編集 ")])], 1)])], 1);
  }), 0) : _vm._e()], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };