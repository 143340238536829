var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_setup.isLoading ? _c(_setup.LoadingMessage) : _c('main', {
    staticClass: "main page_information_create"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'forums'
      }
    }
  }, [_vm._v(" 一覧に戻る ")]), _c('h1', {
    staticClass: "title--page"
  }, [_vm._v("記事詳細")]), _c('section', {
    staticClass: "forum"
  }, [_c('div', {
    staticClass: "forum__header"
  }, [_c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _setup.forumData.published_at
    }
  }, [_vm._v(_vm._s(_setup.moment(_setup.forumData.published_at).format("YYYY年MM月DD日 HH時mm分")))]), _setup.forumData.author_name ? _c('p', {
    staticClass: "contributor"
  }, [_vm._v(" " + _vm._s(_setup.forumData.author_name) + "先生 ")]) : _vm._e(), _setup.forumData.teacher_id === _setup.me.teacher_id ? _c('router-link', {
    staticClass: "edit-button",
    attrs: {
      "to": {
        name: 'forums.edit',
        params: {
          id: _setup.forumData.id
        }
      }
    }
  }, [_vm._v("編集")]) : _vm._e(), _setup.forumData.teacher_id === _setup.me.teacher_id ? _c('router-link', {
    staticClass: "delete-button",
    attrs: {
      "to": ""
    },
    nativeOn: {
      "click": function click($event) {
        return _setup.handleForumDelete.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除 ")]) : _vm._e()], 1), _c('div', {
    staticClass: "forum__title"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_setup.forumData.title))]), _c('div', {
    staticClass: "other"
  }, [_setup.s3AttachedPath ? _c('div', {
    staticClass: "other__item"
  }, [_c('i', {
    staticClass: "fa fa-paperclip"
  })]) : _vm._e(), _c('div', {
    staticClass: "other__item"
  }, [_c('i', {
    staticClass: "far fa-comment"
  }), _c('span', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_setup.forumData.school_forum_comment.length) + " ")])])])]), _setup.forumData.message ? _c('div', {
    staticClass: "forum__body"
  }, _vm._l(_setup.forumData.message.split('\n'), function (text, i) {
    return _c('p', {
      key: i
    }, [_c(_setup.UrlToLink, {
      attrs: {
        "text": text
      }
    })], 1);
  }), 0) : _vm._e(), _setup.s3AttachedPath ? _c('div', {
    staticClass: "forum__files"
  }, _vm._l(_setup.forumData.attachment, function (attachment) {
    return _c('ForumAttachmentArticle', {
      key: attachment.id,
      attrs: {
        "attachment": attachment
      }
    });
  }), 1) : _vm._e()]), _c(_setup.ForumDetailComment, {
    attrs: {
      "comment-list": _setup.forumData.school_forum_comment
    },
    on: {
      "setData": _setup.setData
    }
  }), _c('section', {
    staticClass: "other-forum"
  }, [_c('div', {
    staticClass: "other-forum__header"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("その他の記事一覧")])]), _c('div', {
    staticClass: "other-forum__wrapper"
  }, _vm._l(_setup.forumList, function (forum, index) {
    return _c(_setup.ForumArticle, {
      key: index,
      attrs: {
        "forum": forum
      }
    });
  }), 1)]), _setup.isShowConfirmModal ? _c(_setup.ConfirmSlotModal, {
    attrs: {
      "click-ok": _setup.deleteForum,
      "click-cancel": _setup.hideConfirmModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("記事削除")];
      },
      proxy: true
    }], null, false, 2445985920)
  }, [_vm._v(" 記事を削除します。よろしいですか？ ")]) : _vm._e(), _setup.isShowCompleteModal ? _c(_setup.MessageModal, {
    on: {
      "close": _setup.completeDelete
    }
  }) : _vm._e()], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };