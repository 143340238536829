import store from "../../services/store.js";
export default {
  store: store,
  name: "MessageModal",
  props: {
    useSubButton: {
      type: Boolean,
      default: false
    },
    subButtonMessage: {
      type: String,
      default: ""
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    getTitle: function getTitle() {
      return this.settings && this.settings.title ? this.settings.title : this.$store.state.title;
    },
    getMessage: function getMessage() {
      return this.settings && this.settings.message ? this.settings.message : this.$store.state.message;
    }
  }
};