import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { getData, deleteData } from "@/services/axios";
export default {
  name: "AttendanceIndex",
  data: function data() {
    return {
      student: {}
    };
  },
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("students/" + this.$route.params.id).then(function (data) {
        console.log(data);
        _this.student = {
          id: data.id,
          classroom: "",
          name: data.name,
          ruby: data.ruby,
          gender: data.gender
        };
      });
    },
    deleteStudent: function deleteStudent() {
      var _this2 = this;

      deleteData("students/" + this.student.id).then(function () {
        _this2.$router.push({
          name: "students"
        });
      });
    }
  }
};