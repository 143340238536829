import "core-js/modules/es.array.push.js";
import { createData } from "@/services/axios";
import TOS from "@/components/agreements/TOS";
import PrivacyPolicy from "@/components/agreements/PrivacyPolicy";
export default {
  name: "Agreements",
  components: {
    TOS: TOS,
    PrivacyPolicy: PrivacyPolicy
  },
  data: function data() {
    return {
      agreements: {
        toc: null,
        pp: null
      },
      isWriting: false
    };
  },
  computed: {
    agreementCheck: function agreementCheck() {
      return !!(this.agreements.toc && this.agreements.pp);
    }
  },
  methods: {
    appStart: function appStart() {
      var _this = this;

      this.isWriting = true;
      createData("writer/write_last_login").then(function () {
        _this.$router.push({
          name: "dashboard"
        });
      }).catch(function (error) {
        _this.isWriting = false;
        alert("初回データ保存に失敗しました");
        console.log(error);
      });
    }
  }
};