import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main qr_gen"
  }, [_setup.isLoading ? _c('div', [_vm._v(" QRコード生成中... "), _vm._l(3, function (n) {
    return _c('content-placeholders', {
      key: n,
      staticClass: "placeholder"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    }), _c('content-placeholders-img')], 1);
  })], 2) : _c('div', _vm._l(_setup.qrUrlList, function (qrUrl, i) {
    return _c('div', {
      key: i,
      staticClass: "qr_display tac"
    }, [_c('p', {
      staticClass: "student_name"
    }, [_vm._v(" " + _vm._s(_setup.school.name) + " " + _vm._s(_setup.route.params.belong) + " " + _vm._s(i + 1) + "人目 ")]), _c('qrcode', {
      attrs: {
        "value": qrUrl.url,
        "options": {
          width: 400,
          errorCorrectionLevel: 'H'
        }
      }
    }), _c('br'), _c('a', {
      staticClass: "student_link",
      attrs: {
        "href": qrUrl.url,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(qrUrl.url) + " ")]), _c('div', {
      staticClass: "comment"
    }, [_c('p', {
      staticClass: "expire_on"
    }, [_vm._v(" 有効期限　" + _vm._s(_setup.moment(qrUrl.expire).format("YYYY年MM月DD日")) + " ")]), _c('br'), _c('p', [_vm._v("前日までにご登録ください。")]), _c('p', [_vm._v("期限切れの場合、学校様へ再発行をご依頼ください。")])])], 1);
  }), 0), _setup.isShowNotice ? _c('div', {
    staticClass: "notice"
  }, [_c('p', [_vm._v("登録関係者一覧ページに戻り、再設定してください。")])]) : _setup.isShowError ? _c('div', {
    staticClass: "notice"
  }, [_c('p', [_vm._v("作成に失敗しました。")]), _c('p', [_vm._v("登録関係者一覧ページに戻り、再設定してください。")])]) : _vm._e(), _c('div', {
    staticClass: "button_wrapper tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'participants'
      }
    }
  }, [_vm._v(" 戻る ")])], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };