var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v("お知らせの内容を確認してください")]), _c('div', {
    staticClass: "common_modal__text"
  }, [_c('main', [_c('div', [_c('div', {
    staticClass: "message"
  }, [_c('h2', {
    staticClass: "subject"
  }, [_vm._v(" " + _vm._s(_vm.message.subject) + " ")]), _vm.message.mail_body ? _c('div', {
    staticClass: "message_body"
  }, [_c('p', {
    staticClass: "message_body__guardian_name"
  }, [_vm._v(" 【保護者名】 様、【学校名】 から 【生徒名】 さん宛てのメッセージです。 ")]), _vm._l(_vm.message.mail_body.split('\n'), function (paragraph) {
    return _c('p', {
      key: paragraph
    }, [_c(_setup.UrlToLink, {
      attrs: {
        "text": paragraph
      }
    })], 1);
  })], 2) : _vm._e()]), _vm.message.attached_file ? _c('div', {
    staticClass: "attachment"
  }, [_c('h3', [_vm._v("添付ファイル")]), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click-preview-attachment');
      }
    }
  }, [_vm._v("プレビュー")]), _c('p', [_vm._v("（30分以内にプレビューしてください）")])]) : _vm._e(), _vm.message.useEnquete && _vm.enquete && _vm.enquete.subject ? _c('div', {
    staticClass: "enquete"
  }, [_c('div', [_c('div', {
    staticClass: "guide"
  }, [_c('p', [_vm._v(" このメッセージにはアンケートが設定されていますので、ご協力をお願いいたします。"), _c('br'), _vm._v(" アンケートの期限は"), _c('strong', [_vm._v(_vm._s(_setup.enqueteEnd))]), _vm._v("までです。"), _c('br'), _c('br'), _vm._v(" （プレビュー表示のため、回答を送信することはできません） ")])]), _c('h2', {
    staticClass: "subject"
  }, [_vm._v(" " + _vm._s(_vm.enquete.subject) + " ")]), _vm._l(_vm.enquete.questions, function (question, q_index) {
    return _c('div', {
      key: q_index,
      staticClass: "question"
    }, [_c('h3', {
      staticClass: "question_subject"
    }, [_vm._v(" " + _vm._s(question.subject) + " ")]), question.type === 'single' ? _c('ul', {
      staticClass: "enquete_choices"
    }, _vm._l(question.options, function (option, choices_index) {
      return _c('li', {
        key: choices_index
      }, [_c('input', {
        attrs: {
          "id": ['question_' + q_index + '_select_' + choices_index],
          "type": "radio",
          "disabled": true
        },
        domProps: {
          "value": choices_index
        }
      }), _c('label', {
        attrs: {
          "for": ['question_' + q_index + '_select_' + choices_index]
        }
      }, [_vm._v(_vm._s(option))])]);
    }), 0) : _c('div', {
      staticClass: "textarea_wrap"
    }, [_c('textarea', {
      attrs: {
        "rows": "6",
        "cols": "40",
        "disabled": true,
        "maxlength": "200"
      }
    })])]);
  })], 2)]) : _vm._e()])])]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "select_button",
    attrs: {
      "type": "button",
      "value": "送信先を確認"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click-check-target');
      }
    }
  }), _c('span', {
    staticClass: "selected_count"
  }, [_vm._v(_vm._s(_vm.message.sendTargets.students.length + _vm.message.sendTargets.participants.length) + "人選択中")])]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('div', {
    staticClass: "common_modal__bottom tac"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "キャンセル"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click-cancel');
      }
    }
  }), (_setup.me.role_id <= 11 || _setup.school.meta && _setup.school.meta.allowNoSignatureMessaging) && !_vm.processReviewing ? _c('input', {
    staticClass: "execution_button",
    attrs: {
      "type": "button",
      "value": "お知らせを送信する"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click-send');
      }
    }
  }) : _vm._e(), _vm.processReviewing ? _c('input', {
    staticClass: "execution_button",
    attrs: {
      "type": "button",
      "value": "承認依頼"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click-request-approval');
      }
    }
  }) : _vm._e()])])])])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };