import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_dashboard"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]), _c(_setup.RowItem, [_c('div', {
    staticClass: "news col_8"
  }, [_vm.news.title ? _c('router-link', {
    attrs: {
      "to": {
        name: 'news'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.news.title) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "phone col_4"
  }, [_vm._v(" 欠席受付番号 "), _c('span', [_vm._v(_vm._s(_setup.phoneAttendance))])])]), _c('div', {
    staticClass: "forum-wrapper"
  }, [_setup.schoolPlan.forum ? _c(_setup.LatestSchoolForum) : _vm._e(), _setup.schoolPlan.forum && _vm.school.government && _vm.school.government.length > 0 ? _c(_setup.LatestGovernmentForum) : _vm._e()], 1), _c(_setup.RowItem, {
    staticClass: "start between dashboard_top"
  }, [_c(_setup.LatestAttendanceCounter), _c(_setup.LatestAbsenceInfo, {
    attrs: {
      "is-loading": _vm.isLoading.attendance,
      "attendances": _vm.attendances
    }
  }), _c('div', {
    staticClass: "logs_information"
  }, [_c('div', {
    staticClass: "logs"
  }, [_c(_setup.RowItem, {
    staticClass: "between heading"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("最近ログインした先生")]), _c('router-link', {
    staticClass: "show_button",
    attrs: {
      "to": {
        name: 'logs.login'
      }
    }
  }, [_vm._v(" 一覧を見る ")])], 1), _c(_setup.RowItem, {
    staticClass: "wrap"
  }, _vm._l(_vm.loginLogs, function (log, i) {
    return _c('div', {
      key: i,
      staticClass: "logs__item"
    }, [_c('p', {
      staticClass: "logs__name"
    }, [_vm._v(" " + _vm._s(log.teacher ? log.teacher.name : "") + " ")]), _c('p', {
      staticClass: "logs__time"
    }, [_vm._v(" " + _vm._s(log.fromNow) + " ")])]);
  }), 0)], 1), _c('div', {
    staticClass: "information"
  }, [_c(_setup.RowItem, {
    staticClass: "between heading"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("最近のお知らせ")]), _c('router-link', {
    staticClass: "show_button",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" 一覧を見る ")])], 1), _c('div', {
    staticClass: "inner"
  }, [_vm.isLoading.message ? _c('content-placeholders', _vm._l(5, function (n) {
    return _c('content-placeholders-text', {
      key: n,
      attrs: {
        "lines": 1
      }
    });
  }), 1) : _vm._e(), _vm._l(_vm.messages, function (message, i) {
    return _c(_setup.RowItem, {
      key: i,
      staticClass: "list"
    }, [_c('div', {
      staticClass: "data_wrap col_9"
    }, [message.is_visible ? _c('router-link', {
      attrs: {
        "to": {
          name: 'messages.detail',
          params: {
            id: message.id
          }
        }
      }
    }, [_c('p', [message.visibility === 'limited' ? _c('img', {
      attrs: {
        "src": require("@/assets/images/keylock.svg"),
        "alt": "keylock",
        "width": "14px",
        "height": "auto"
      }
    }) : _vm._e(), message.attached_file ? _c('i', {
      staticClass: "fas fa-paperclip"
    }) : _vm._e(), _vm._v(" " + _vm._s(message.subject) + " ")])]) : _c('p', [message.visibility === 'limited' ? _c('img', {
      attrs: {
        "src": require("@/assets/images/keylock.svg"),
        "alt": "keylock",
        "width": "14px",
        "height": "auto"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(message.subject) + " ")]), _c('p', {
      staticClass: "date"
    }, [_c('time', {
      attrs: {
        "datetime": ""
      }
    }, [_vm._v(_vm._s(message.send_at))]), _c('span', [_vm._v(_vm._s(message.teacher_name) + "先生作成")])])], 1), _c('div', {
      staticClass: "col_3"
    }, [message.is_visible ? _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'messages.status',
          params: {
            id: message.id
          }
        }
      }
    }, [_vm._v(" 詳細 ")]) : _vm._e()], 1)]);
  })], 2)], 1)])], 1), _c('div', {
    staticClass: "flex_box"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.school.name) + " 全校" + _vm._s(_vm.studentAddress) + "出欠席 " + _vm._s(_vm.currentDt) + "現在 ")]), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button__small"
  }, [_c('button', {
    staticClass: "switch_button__left item",
    class: {
      active: !_vm.isGradeTables
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleDashboard(false);
      }
    }
  }, [_vm._v(" 欠席者数 ")]), _c('button', {
    staticClass: "switch_button__right item",
    class: {
      active: _vm.isGradeTables
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleDashboard(true);
      }
    }
  }, [_vm._v(" 欠席者一覧 ")])])])]), _vm.isGradeTables ? _c(_setup.GradeTable, {
    attrs: {
      "today-attendance": _vm.todayAttendance
    }
  }) : _c(_setup.AllClassAbsenceCounter), _c('section', {
    staticClass: "enquetes"
  }, [_c('div', {
    staticClass: "col2_frame__inner"
  }, [_c(_setup.RowItem, {
    staticClass: "between heading"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("最近のアンケート")]), _c('router-link', {
    staticClass: "show_button",
    attrs: {
      "to": {
        name: 'enquetes'
      }
    }
  }, [_vm._v(" アンケート一覧を見る ")])], 1), _c('div', {
    staticClass: "inner"
  }, [_vm.isLoading.enquete ? _c('content-placeholders', _vm._l(5, function (n) {
    return _c('content-placeholders-text', {
      key: n,
      attrs: {
        "lines": 1
      }
    });
  }), 1) : _vm._l(_vm.enquetes, function (enquete, i) {
    return _c(_setup.RowItem, {
      key: i,
      staticClass: "between list"
    }, [_c('div', {
      staticClass: "data_wrap"
    }, [_c('p', {
      staticClass: "event"
    }, [enquete.visibility === 'limited' ? _c('img', {
      attrs: {
        "src": require("@/assets/images/keylock.svg"),
        "alt": "keylock",
        "width": "14px",
        "height": "auto"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(enquete.subject) + " ")]), _c('p', {
      staticClass: "date"
    }, [_c('time', [_vm._v(_vm._s(enquete.implement_to) + "まで")]), _c('span', [_vm._v(_vm._s(enquete.teacher_name) + "先生作成")])])]), enquete.is_visible ? _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'enquetes.result',
          params: {
            id: enquete.id
          }
        }
      }
    }, [_vm._v(" 詳細 ")]) : _vm._e()], 1);
  })], 2)], 1)]), _vm.showModal ? _c(_setup.MessageModal, {
    attrs: {
      "use-sub-button": true,
      "sub-button-message": '詳しく確認する'
    },
    on: {
      "close": _vm.closeMessageModal,
      "click-sub-button": _vm.navigateToElevate
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };