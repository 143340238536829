import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_student_delete"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("生徒削除")]), _c('section', {
    staticClass: "confirm_text"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.student.classroom) + " "), _c('span', {
    staticClass: "alert_text"
  }, [_vm._v(_vm._s(_vm.student.name))]), _vm._v("（" + _vm._s(_vm.student.ruby) + "）を削除します。 ")]), _c('p', [_vm._v("紐付いてる保護者情報も合わせて削除されますがよろしいですか？")])]), _c('section', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'students'
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('input', {
    staticClass: "delete_button",
    attrs: {
      "type": "button",
      "value": "削除する"
    },
    on: {
      "click": _vm.deleteStudent
    }
  })], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };