import store from "../../services/store.js";
import { getData } from "@/services/axios";
import moment from "moment";
export default {
  store: store,
  name: "SelectEnquete",
  data: function data() {
    return {
      enquetes: [],
      selectedId: false,
      isLoading: true
    };
  },
  created: function created() {
    this.setData();
  },
  methods: {
    handleClick: function handleClick(mode) {
      this.$emit("select", {
        mode: mode,
        enquete_id: this.selectedId
      });
    },
    setData: function setData() {
      var _this = this;

      getData("enquetes?locale=ja-JP&for_copy=1").then(function (data) {
        _this.enquetes = data.items;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading = false;
      });
    },
    getJPTime: function getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },
    isInProgress: function isInProgress(datetime) {
      return moment().startOf("day").isBefore(moment(datetime).startOf("day"));
    }
  }
};