var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_login"
  }, [_vm._m(0), _c('div', {
    staticClass: "input_area"
  }, [!_vm.MFACodeIsSent ? _c('div', [_c('div', [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendMFACode.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "common_form--input",
    attrs: {
      "type": "email",
      "placeholder": "メールアドレスを入力してください"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.email = $event.target.value;
      }
    }
  }), _vm.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.email) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "button_wrapper"
  }, [_c('a', {
    staticClass: "login_button",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendMFACode.apply(null, arguments);
      }
    }
  }, [_vm._v(" 認証コード送信 ")])])]) : _c('div', [_c('p', {
    staticClass: "submit_message"
  }, [_vm._v(" 指定されたメールアドレスに認証コードを送信しました。 ")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.MFACode,
      expression: "MFACode"
    }],
    staticClass: "common_form--input",
    attrs: {
      "type": "text",
      "placeholder": "認証コードを入力してください"
    },
    domProps: {
      "value": _vm.MFACode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.MFACode = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "button_wrapper"
  }, [_vm.MFACode.length >= 6 ? _c('a', {
    staticClass: "login_button",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v(" ログイン ")]) : _vm._e()]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.MFACodeIsSent = false;
      }
    }
  }, [_vm._v(" 認証コードを再送信する ")])])]), _vm.news.loginMessage ? _c('div', {
    staticClass: "info"
  }, [_c('pre', [_vm._v(_vm._s(_vm.news.loginMessage))])]) : _vm._e(), _vm._m(1)]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('h2', {
    staticClass: "title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.jpg"),
      "alt": "ロゴ画像：COCOO"
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "description"
  }, [_vm._v(" 許可された先生のみ、学校外からログインすることができます。"), _c('br'), _vm._v(" 1. ログイン用メールアドレスを入力し、認証コード送信ボタンを押してください。"), _c('br'), _vm._v(" 2. 届いたメールに記された認証コードを認証コード欄に入力し、学校外ログインボタンを押してください。 ")]);
}];
export { render, staticRenderFns };