import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.sort.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
export default {
  store: store,
  name: "StudentCSVUpload",
  components: {
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      csv: [],
      error: [],
      warning: [],
      tableError: [],
      tableWarning: [],
      countByGrades: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false
    };
  },
  computed: _objectSpread({}, mapState(["classrooms", "grades", "studentAddress", "me"])),
  created: function created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  methods: {
    downloadTemplateCSV: function downloadTemplateCSV() {
      var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["学年", "学年組", "氏名", "氏名かな", "性別"]])], {
        type: "text/csv;charset=utf-8"
      }), "student_template.csv");
    },
    setAttachment: function setAttachment(e) {
      var _this = this;

      var csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.warning = [];
      this.tableError = [];
      this.tableWarning = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      var reader = new FileReader();

      reader.onload = function (e) {
        var codes = new Uint8Array(e.target.result);
        var encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        var from = encoding === "UTF8" ? "UTF8" : "SJIS";
        var csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        var csv = Papa.parse(csvString);
        _this.csv = _this.csvDataCleaning(csv);

        _this.calculateCountByGrades(); //同名ファイルの再アップロードを検知するためにリセット


        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },
    csvDataCleaning: function csvDataCleaning(csv) {
      var _this2 = this;

      //エラーテーブルを初期化
      csv.data.forEach(function (v) {
        var row = [];
        v.forEach(function () {
          return row.push(false);
        });

        _this2.tableError.push(row);

        _this2.tableWarning.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(function (v) {
          return v.message;
        });
      }

      var data = [];
      csv.data.forEach(function (row, i) {
        if (i === 0) {
          if (row.join() !== "学年,学年組,氏名,氏名かな,性別") {
            _this2.error.push("見出し行が「学年」「学年組」「氏名」「氏名かな」「性別」と異なります");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
          }

          return data.push(row);
        }

        if (row.length !== 5) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            _this2.error.push(i + 1 + "行目の値が5列ではありません");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
            _this2.tableWarning[i] = _this2.tableWarning[i].map(function () {
              return true;
            });
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map(function (cell, j) {
          cell = Encoding.toHankakuCase(cell).trim();
          var valid = true;

          if (j === 0) {
            if (!_this2.grades.find(function (g) {
              return g.name === cell;
            })) {
              _this2.error.push(i + 1 + "行目の学年はCOCOOに登録されていない値です");

              valid = false;
            }
          }

          if (j === 1) {
            if (!_this2.classrooms.find(function (c) {
              return c.name === cell;
            })) {
              _this2.error.push(i + 1 + "行目の学年組はCOCOOに登録されている学年組(クラス名)と異なります");

              valid = false;
            } else if (!_this2.grades.find(function (g) {
              return g.classroom.find(function (c) {
                return c.name === cell;
              }) && g.name === Encoding.toHankakuCase(row[0]).trim();
            })) {
              _this2.error.push(i + 1 + "行目の学年組は学年との組み合わせが間違っています");

              valid = false;
            }
          }

          if (j === 2) {
            if (!cell) {
              _this2.error.push(i + 1 + "行目の氏名を入力してください");

              valid = false;
            } else if (!cell.match(/\s/)) {
              _this2.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください");

              valid = false;
            }

            if (cell && _this2.error.length === 0) {
              if (cell.match(/\?/) || cell.match(/__/)) {
                _this2.warning.push(i + 1 + "行目の氏名に文字化けが含まれる可能性があります");

                valid = false;
              }
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 3) {
            if (!cell) {
              _this2.error.push(i + 1 + "行目の氏名かなを入力してください");

              valid = false;
            } else if (!cell.match(/\s/)) {
              _this2.error.push(i + 1 + "行目の氏名かなの姓と名の間に半角スペースを入れてください");

              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 4 && cell && !(cell === "男" || cell === "女")) {
            _this2.error.push(i + 1 + "行目の性別は男あるいは女を入力するか、空欄としてください");

            valid = false;
          }

          if (!valid) _this2.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return data;
    },
    calculateCountByGrades: function calculateCountByGrades() {
      var gradesGroup = {};
      this.csv.forEach(function (row, i) {
        if (i === 0) return;

        if (gradesGroup[row[0]]) {
          gradesGroup[row[0]]++;
        } else {
          gradesGroup[row[0]] = 1;
        }
      });
      var a = []; //学年順に並び替え

      Object.keys(gradesGroup).forEach(function (k) {
        a.push([k, gradesGroup[k]]);
      });

      var compare = function compare(a, b) {
        if (a[0] < b[0]) {
          return -1;
        }

        if (a[0] > b[0]) {
          return 1;
        }

        return 0;
      };

      a.sort(compare);
      this.countByGrades = a;
    },
    saveData: function saveData() {
      var _this3 = this;

      this.isLoading = true;
      var gradesMap = {};
      var classroomsMap = {};
      var genderMap = {
        男: 1,
        女: 2
      };
      this.grades.forEach(function (v) {
        return gradesMap[v.name] = v.id;
      });
      this.classrooms.forEach(function (v) {
        return classroomsMap[v.name] = v.id;
      }); //ヘッダー行の除去

      this.csv.shift();
      var students = this.csv.map(function (v) {
        return {
          grade_id: gradesMap[v[0]],
          class_id: classroomsMap[v[1]],
          name: v[2],
          ruby: v[3],
          gender: genderMap[v[4]]
        };
      });
      console.log(students);
      createData("writer/bulk_write_student", {
        students: students
      }).then(function () {
        _this3.$store.dispatch("commitModalMessage", {
          message: _this3.studentAddress + "情報一括登録が完了しました",
          title: "登録完了"
        });

        _this3.showModal = true;
        _this3.isLoading = false;
      }).catch(function (error) {
        _this3.$store.dispatch("commitModalMessage", {
          message: _this3.studentAddress + "情報一括登録に失敗しました",
          title: "登録失敗"
        });

        console.log(error);
        _this3.showModal = true;
        _this3.isLoading = false;
      });
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "students"
      });
    },
    open: function open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },
    close: function close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }
  }
};