import "core-js/modules/es.number.constructor.js";
import { ref } from "vue";
export default {
  __name: 'MonthDailyEvent',
  props: {
    mode: {
      type: String,
      default: "attendance"
    },
    days: {
      type: Number,
      required: true
    },
    monthData: {
      /** @type {PropType<Object<string, any>[]>} */
      type: Array,
      required: true
    },
    dailyEvents: {
      type: Object,
      default: function _default() {}
    },
    classroom: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(__props) {
    // @ts-check
    var isOmitted = ref(true);

    var handleOmitted = function handleOmitted() {
      isOmitted.value = !isOmitted.value;
    };

    return {
      __sfc: true,
      isOmitted: isOmitted,
      handleOmitted: handleOmitted
    };
  }
};