import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', [_vm.isLoading.teacher || _vm.isLoading.classroom || _vm.isLoading.classTeacher ? _c('loading-message', {
    attrs: {
      "loading-message": "先生データを取得しています"
    }
  }) : _vm.isLoading.postTeacher ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _c('main', {
    staticClass: "main page_school_teacher_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("学校管理 -" + _vm._s(_vm.pageTitle) + "-")]), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('row-item', {
    staticClass: "start teacher_item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("氏名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.teacher.name,
      expression: "teacher.name"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "type": "text",
      "value": ""
    },
    domProps: {
      "value": _vm.teacher.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.teacher, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "start teacher_item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("メールアドレス")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.teacher.email,
      expression: "teacher.email"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "type": "text",
      "value": ""
    },
    domProps: {
      "value": _vm.teacher.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.teacher, "email", $event.target.value);
      }
    }
  }), _vm.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.email) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "teacher_item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("管理者権限 種別")]), _c('div', {
    staticClass: "col_9"
  }, _vm._l(_vm.role_radio_options, function (role, i) {
    return _c('div', {
      key: i,
      staticClass: "common_form--radio_wrap"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.teacher.role_id,
        expression: "teacher.role_id"
      }],
      staticClass: "common_form--radio",
      attrs: {
        "id": 'role_' + role.id,
        "type": "radio"
      },
      domProps: {
        "value": role.id,
        "checked": _vm._q(_vm.teacher.role_id, role.id)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.teacher, "role_id", role.id);
        }
      }
    }), _c('label', {
      staticClass: "common_form__label",
      attrs: {
        "for": 'role_' + role.id
      }
    }, [_vm._v(_vm._s(role.label))])]);
  }), 0)]), _c('row-item', {
    staticClass: "start teacher_item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("クラス")]), _c('div', {
    staticClass: "col_9"
  }, _vm._l(_vm.gradeClass, function (grade, i) {
    return _c('row-item', {
      key: i,
      staticClass: "wrap"
    }, _vm._l(grade.classroom, function (classroom) {
      return _c('div', {
        key: classroom.id,
        staticClass: "col_2 checkbox"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.classrooms,
          expression: "classrooms"
        }],
        staticClass: "common_form--checkbox",
        attrs: {
          "id": ['check_' + classroom.id],
          "type": "checkbox"
        },
        domProps: {
          "value": classroom.id,
          "checked": Array.isArray(_vm.classrooms) ? _vm._i(_vm.classrooms, classroom.id) > -1 : _vm.classrooms
        },
        on: {
          "change": function change($event) {
            var $$a = _vm.classrooms,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = classroom.id,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && (_vm.classrooms = $$a.concat([$$v]));
              } else {
                $$i > -1 && (_vm.classrooms = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.classrooms = $$c;
            }
          }
        }
      }), _c('label', {
        staticClass: "common_form--label",
        attrs: {
          "for": ['check_' + classroom.id]
        }
      }, [_vm._v(_vm._s(classroom.name))])]);
    }), 0);
  }), 1)]), _c('row-item', {
    staticClass: "teacher_item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("学外ログイン")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form__radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.teacher.use_mfa,
      expression: "teacher.use_mfa"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "use_mfa",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.teacher.use_mfa) ? _vm._i(_vm.teacher.use_mfa, true) > -1 : _vm.teacher.use_mfa
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.teacher.use_mfa,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.teacher, "use_mfa", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.teacher, "use_mfa", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.teacher, "use_mfa", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "use_mfa"
    }
  }, [_vm._v(" 学校外からのログインを許可する ")])])])]), !_vm.$route.params.id ? _c('row-item', {
    staticClass: "teacher_item start"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("先生へのお知らせ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form__radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.notifyRegistered,
      expression: "notifyRegistered"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "notify_registered",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.notifyRegistered) ? _vm._i(_vm.notifyRegistered, true) > -1 : _vm.notifyRegistered
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.notifyRegistered,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.notifyRegistered = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.notifyRegistered = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.notifyRegistered = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "notify_registered"
    }
  }, [_vm._v(" お知らせを送信する ")]), _c('p', {
    staticClass: "notice"
  }, [_vm._v(" ※お知らせを送信するにチェックを入れると登録された先生にログイン方法のご案内を送ります。 ")])])])]) : _vm._e(), _vm.$route.params.id ? _c('row-item', {
    staticClass: "teacher_item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("ログイン停止")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form__radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.teacher.account_lock,
      expression: "teacher.account_lock"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "account_lock",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.teacher.account_lock) ? _vm._i(_vm.teacher.account_lock, true) > -1 : _vm.teacher.account_lock
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.teacher.account_lock,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.teacher, "account_lock", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.teacher, "account_lock", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.teacher, "account_lock", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "account_lock"
    }
  }, [_vm._v(" ログインを停止する ")])])])]) : _vm._e(), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'teachers'
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "disabled": _vm.buttonDisabled,
      "value": _vm.buttonLabel
    },
    on: {
      "click": _vm.saveData
    }
  })], 1), !(_vm.teacher.user_id && _vm.teacher.user_id === _vm.me.user_id) ? [_vm.$route.params.id ? _c('div', {
    staticClass: "form_with_message"
  }, [_c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('button', {
    staticClass: "delete_button",
    attrs: {
      "disabled": _vm.buttonDisabled
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmTransfer.apply(null, arguments);
      }
    }
  }, [_vm._v(" 異動する ")])]), _c('p', [_vm._v(" 他校へ異動した先生はこの学校にログインできなくなり、"), _c('br'), _vm._v(" 新しい学校で再登録できるようになります。 ")])]) : _vm._e(), _vm.$route.params.id ? _c('div', {
    staticClass: "form_with_message"
  }, [_c('section', {
    staticClass: "common_form__button_wrap"
  }, [_vm.$route.params.id ? _c('button', {
    staticClass: "delete_button",
    attrs: {
      "disabled": _vm.buttonDisabled
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmDelete.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除する ")]) : _vm._e()]), _c('p', [_vm._v("今後COCOOを利用しない場合は、削除してください。")])]) : _vm._e()] : _vm._e()], 2), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.completeCreate
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.handleDeleteData,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };