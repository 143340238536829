import "core-js/modules/es.array.push.js";
import { createData, deleteData, getData, updateData } from "@/services/axios";
import MessageModal from "@/components/common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../../RowItem";
export default {
  name: "MessageTemplateEditor",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    ConfirmModal: ConfirmModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      editData: {
        template_name: "",
        is_emergency: false,
        subject: "",
        mail_body: "",
        call_body: ""
      },
      error: {
        template_name: null,
        subject: null,
        mail_body: null,
        call_body: null
      },
      isLoading: true,
      showModal: false,
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: ""
    };
  },
  head: {
    title: function title() {
      return {
        inner: "テンプレート管理"
      };
    },
    meta: []
  },
  watch: {
    "editData.mail_body": function editDataMail_body(newval, oldval) {
      if (this.editData.call_body === "" || this.editData.call_body === oldval) {
        this.editData.call_body = newval;
      }
    }
  },
  created: function created() {
    this.editData.is_emergency = !!(this.$route.query.mode && this.$route.query.mode === "emergency");

    if (this.$route.params.id !== undefined) {
      this.setData();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("messages/templates/" + this.$route.params.id).then(function (result) {
        _this.editData = result.items[0];
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading = false;
      });
    },
    doSave: function doSave() {
      var _this2 = this;

      var valid = true;
      this.error = {
        template_name: "",
        subject: "",
        mail_body: "",
        call_body: ""
      };

      if (!this.editData.template_name) {
        this.error.template_name = "テンプレート名を指定してください";
        valid = false;
      }

      if (!this.editData.subject) {
        this.error.subject = "メールの件名を指定してください";
        valid = false;
      }

      if (!this.editData.mail_body) {
        this.error.mail_body = "メールのメッセージ本文を指定してください";
        valid = false;
      }

      if (!this.editData.call_body) {
        this.error.call_body = "電話連絡のメッセージ本文を指定してください";
        valid = false;
      }

      if (!valid) {
        return;
      }

      if (this.$route.params.id === undefined) {
        // post
        createData("messages/templates", this.editData).then(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "テンプレートを作成しました",
            title: "作成完了"
          });
        }).catch(function (error) {
          _this2.$store.dispatch("commitModalMessage", {
            message: "テンプレート作成に失敗しました",
            title: "作成失敗"
          });

          console.log(error);
        }).then(function () {
          _this2.showModal = true;
        });
      } else {
        // put
        updateData("messages/templates/" + this.$route.params.id, this.editData).then(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "テンプレートを更新しました",
            title: "更新完了"
          });
        }).catch(function (error) {
          _this2.$store.dispatch("commitModalMessage", {
            message: "テンプレート更新に失敗しました",
            title: "更新失敗"
          });

          console.log(error);
        }).then(function () {
          _this2.showModal = true;
        });
      }
    },
    confirmDelete: function confirmDelete() {
      this.confirmMessageTitle = "テンプレート削除";
      this.confirmMessage = "テンプレートを削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },
    doDelete: function doDelete() {
      var _this3 = this;

      // delete
      deleteData("messages/templates/" + this.$route.params.id).then(function () {
        // 成功
        _this3.$store.dispatch("commitModalMessage", {
          message: "テンプレートを削除しました",
          title: "削除完了"
        });
      }).catch(function (error) {
        _this3.$store.dispatch("commitModalMessage", {
          message: "テンプレート削除に失敗しました",
          title: "削除失敗"
        });

        console.log(error);
      }).then(function () {
        _this3.showModal = true;
      });
      this.showConfirmModal = false;
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    },
    modalClose: function modalClose() {
      this.showModal = false;
      this.$router.push({
        name: "messages.templates"
      });
    }
  }
};