import { useRoute } from 'vue-router/composables';
export default {
  __name: 'ForumSwitch',
  setup: function setup(__props) {
    // @ts-check
    var route = useRoute();
    return {
      __sfc: true,
      route: route
    };
  }
};