import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "vue2-helpers/vuex";
import { storeToRefs } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import { useAbsentAlertStore } from "@/stores/absentAlert";
import MonthlyAttendance from "@/components/attendances/monthly/MonthlyAttendance.vue";
import MonthlyTemperature from "@/components/temperatures/MonthlyTemperature.vue";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
export default {
  __name: 'Monthly',
  setup: function setup(__props) {
    // @ts-check
    var route = useRoute();
    var store = useStore();

    var _storeToRefs = storeToRefs(useLoadingStore()),
        isLoading = _storeToRefs.isLoading;

    var absentAlertStore = useAbsentAlertStore();
    var studentAddress = computed(function () {
      return store.state.studentAddress;
    }); // created

    absentAlertStore.getAbsentAlert();
    return {
      __sfc: true,
      route: route,
      store: store,
      isLoading: isLoading,
      absentAlertStore: absentAlertStore,
      studentAddress: studentAddress,
      MonthlyAttendance: MonthlyAttendance,
      MonthlyTemperature: MonthlyTemperature,
      LoadingMessage: LoadingMessage
    };
  }
};