import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import { getData } from "@/services/axios";
import RowItem from "../../RowItem";
export default {
  name: "TemplateIndex",
  components: {
    RowItem: RowItem
  },
  data: function data() {
    return {
      filterOptions: {
        keyword: ""
      },
      templates: {},
      filteredTemplates: [],
      isLoading: true
    };
  },
  head: {
    title: function title() {
      return {
        inner: "テンプレート一覧"
      };
    },
    meta: []
  },
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("messages/templates").then(function (data) {
        _this.templates = _this.filteredTemplates = data.items;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading = false;
      });
    },
    listFilter: function listFilter() {
      var newList = this.templates;
      var keyword = this.filterOptions.keyword;

      if (keyword) {
        newList = newList.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(keyword) > -1;
          });
        });
      }

      this.filteredTemplates = newList;
    },
    getTitle: function getTitle(message) {
      return message.template_name ? message.template_name : message.subject;
    }
  }
};