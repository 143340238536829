import "core-js/modules/es.array.concat.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return !_vm.type ? _c('div', {
    staticClass: "row"
  }, [_c('RowItem', [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("事由")]), _vm._l(_vm.attendanceSelectionList, function (item, i_l1) {
    return _c('div', {
      key: i_l1,
      staticClass: "common_form--radio_wrap"
    }, [_c('input', {
      staticClass: "common_form--radio",
      attrs: {
        "id": 'absence_1_' + i_l1,
        "type": "radio"
      },
      domProps: {
        "value": item,
        "checked": item === _vm.attendanceSelectionChildren[0]
      },
      on: {
        "change": function change($event) {
          return _vm.setSelectedType(item, 0);
        }
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'absence_1_' + i_l1
      }
    }, [_vm._v(_vm._s(item.admin ? item.admin : item.label))])]);
  })], 2), _vm._l(_vm.attendanceSelectionChildren.length, function (i) {
    return _c('RowItem', {
      key: i
    }, [_c('p', {
      staticClass: "col_2"
    }), _c('div', {
      staticClass: "col_10 separator"
    }, _vm._l(_vm.attendanceSelectionChildren[i - 1].children, function (item, j) {
      return _c('div', {
        key: j,
        staticClass: "common_form--radio_wrap"
      }, [_c('input', {
        staticClass: "common_form--radio",
        attrs: {
          "id": "absence_".concat(i + 1, "_").concat(j),
          "type": "radio"
        },
        domProps: {
          "value": item,
          "checked": item === _vm.attendanceSelectionChildren[i]
        },
        on: {
          "change": function change($event) {
            return _vm.setSelectedType(item, i);
          }
        }
      }), _c('label', {
        staticClass: "common_form--label",
        attrs: {
          "for": "absence_".concat(i + 1, "_").concat(j)
        }
      }, [_vm._v(_vm._s(item.admin ? item.admin : item.label))])]);
    }), 0)]);
  }), _c('RowItem', [_c('p', {
    staticClass: "col_2"
  }), _c('div', {
    staticClass: "col_10 separator"
  }, [_vm.isShowSetTypeButton ? _c('button', {
    staticClass: "apply_button",
    on: {
      "click": _vm.setType
    }
  }, [_vm._v(" 決定 ")]) : _vm._e()])])], 2) : _c('div', {
    staticClass: "row"
  }, [_c('row-item', {
    staticClass: "common_form--wrap"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("事由")]), _c('div', {
    staticClass: "col_4"
  }, [_vm._v(" " + _vm._s(_vm.type) + " ")]), _c('p', {
    staticClass: "col_6"
  }, [_c('button', {
    staticClass: "change_button",
    on: {
      "click": function click($event) {
        return _vm.resetType();
      }
    }
  }, [_vm._v("変更")])])])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };