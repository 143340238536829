import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import { downloadStudentsCSV } from "@/services/common";
import RowItem from "../RowItem";
import moment from "moment";
import MessageModal from "../common/MessageModal.vue";
import Encoding from "encoding-japanese";
export default {
  name: "ElevateIndex",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal
  },
  data: function data() {
    return {
      graduatingLabel: "卒業学年",
      nextYear: moment().add(9, "month").year(),
      grades: [],
      graduatingGrades: [],
      newGrades: {},
      newClassrooms: {},
      isLoading: {
        classroomData: true,
        postData: false
      },
      showModal: false,
      error: [],
      date: null,
      done: false,
      isConfigured: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["studentAddress"])), {}, {
    fiscalDate: function fiscalDate() {
      return this.date ? moment(this.date).format("YYYY年MM月DD日") : "";
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    downloadStudentsCSV: downloadStudentsCSV,
    setData: function setData() {
      var _this = this;

      getData(["grades?with=classroom", "reader/get_classroom_with_students_count", "schools"]).then(function (data) {
        var elevating = data[2].items[0].elevating; // 去年の年次処理であればリセット

        if (elevating && elevating.date) {
          var firstDateOfYear = moment().startOf("year");

          if (moment(elevating.date).isBefore(firstDateOfYear)) {
            elevating = null;
          }
        }

        _this.isConfigured = Boolean(elevating);

        if (elevating && elevating.done) {
          _this.done = elevating.done;
        }

        _this.date = elevating && elevating.date ? elevating.date : _this.nextYear + "-04-01";
        _this.grades = data[0].items;
        _this.classrooms = data[1].items;
        var newGrades = {};
        var newClassrooms = {};

        _this.grades.forEach(function (v) {
          if (elevating && elevating.newGrades[v.id]) {
            newGrades[v.id] = elevating.newGrades[v.id];
          } else {
            var d = {
              name: v.name,
              beGraduating: false
            }; // 自動持ち上げ処理

            if (v.name.match(/^\d+年/)) {
              var maybeCurrentGradeNumber = v.name.match(/^\d+年/)[0].replace("年", "");

              if (Number(maybeCurrentGradeNumber)) {
                var postfix = v.name.match(/^\d+年/)[1] ? v.name.match(/^\d+年/)[1] : "";
                var newGradeName = Number(maybeCurrentGradeNumber) + 1 + "年" + postfix;

                if (_this.grades.find(function (g) {
                  return g.name === newGradeName;
                })) {
                  d.name = newGradeName;
                } else {
                  d.name = _this.graduatingLabel;
                  d.beGraduating = true;
                }
              }
            }

            newGrades[v.id] = d;
          }

          v.classroom.forEach(function (c) {
            if (elevating && elevating.newClassrooms[c.id]) {
              newClassrooms[c.id] = elevating.newClassrooms[c.id];
            } else {
              var _d = {
                name: c.name,
                grade: c.pivot.grade_id
              }; // 自動持ち上げ処理

              if (newGrades[v.id].beGraduating) {
                _d.name = _d.name.replace(/^.+年/, "卒業学年");
              } else if (newGrades[v.id].name.match(/^\d+年/)) {
                var maybeNewGrade = newGrades[v.id].name.match(/^\d+年/)[0];
                _d.name = _d.name.replace(/^\d+年/, maybeNewGrade);
              }

              newClassrooms[c.id] = _d;
            }
          });
        });

        _this.classrooms.forEach(function (v) {
          _this.grades = _this.grades.map(function (g) {
            g.classroom = g.classroom.map(function (c) {
              if (v.id === c.id) {
                c.studentCount = v.students_count;
              }

              return c;
            }); //todo並び順の保存処理が動作したら有効にする
            // g.classroom.sort((a,b) => {
            //   if (a.index < b.index) return -1;
            //   if (a.index > b.index) return 1;
            //   return 0;
            // })

            return g;
          });
        });

        _this.newGrades = newGrades;
        _this.newClassrooms = newClassrooms;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.classroomData = false;
      });
    },
    handleChangeGrade: function handleChangeGrade(e, grade) {
      var _this2 = this;

      if (e.target.value === this.graduatingLabel) {
        this.newGrades[grade.id].beGraduating = true;
        return;
      } else {
        this.newGrades[grade.id].beGraduating = false;
      }

      var newGrade = e.target.value;
      var renameTargetClassroomIds = grade.classroom.map(function (c) {
        return c.id;
      });
      Object.keys(this.newClassrooms).forEach(function (k) {
        if (renameTargetClassroomIds.indexOf(Number(k)) > -1) {
          var r = new RegExp("^".concat(grade.name));
          _this2.newClassrooms[k].name = _this2.newClassrooms[k].name.replace(r, newGrade);
        }
      });
    },
    handleChangeName: function handleChangeName(e, model) {
      model.name = Encoding.toHankakuCase(e.target.value).trim();
    },
    handleSave: function handleSave() {
      var _this3 = this;

      this.error = [];
      var gradeEmpty = Object.keys(this.newGrades).find(function (k) {
        return !_this3.newGrades[k].beGraduating && !_this3.newGrades[k].name;
      });

      if (gradeEmpty) {
        this.error.push("次年度の学年表記を選択してください");
      } // const classroomEmpty = Object.keys(this.newClassrooms).find(k =>{
      //   return !this.newClassrooms[k].name
      // })
      // if(classroomEmpty){
      //   this.error.push('次年度のクラス表記を入力してください')
      // }


      var classroomDuplicate = Object.keys(this.newClassrooms).find(function (k) {
        if (!_this3.newClassrooms[k].name) return false;
        return Object.keys(_this3.newClassrooms).find(function (k2) {
          return k !== k2 && _this3.newClassrooms[k].name === _this3.newClassrooms[k2].name;
        });
      });

      if (classroomDuplicate) {
        this.error.push("次年度の組表記が重複しています");
      }

      if (this.error.length > 0) return;
      this.isLoading.postData = false;
      createData("writer/write_school_elevating", {
        date: this.date,
        newGrades: this.newGrades,
        newClassrooms: this.newClassrooms
      }).then(function () {
        _this3.$store.dispatch("commitModalMessage", {
          message: "年次処理の設定を保存しました",
          title: "保存完了"
        });

        _this3.isConfigured = true;
        _this3.showModal = true;
        _this3.isLoading.postData = false;
      }).catch(function (error) {
        console.log(error);
      });
    },
    saved: function saved() {
      this.showModal = false;
    }
  }
};