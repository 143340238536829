import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import MessageModal from "../common/MessageModal.vue";
import { getData, createData, updateData, deleteData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import Encoding from "encoding-japanese";
import RowItem from "../RowItem";
export default {
  name: "CreateGrade",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      showModal: false,
      grade: {},
      isLoading: {
        getData: false,
        postData: false
      },
      loadingMessage: "",
      error: {
        name: null
      }
    };
  },
  computed: _objectSpread({}, mapState(["grades"])),
  created: function created() {
    if (this.$route.params.id) {
      this.setData();
    }
  },
  methods: {
    setData: function setData() {
      var _this = this;

      if (this.$route.params.id) {
        this.isLoading.getData = true;
        getData("grades/" + this.$route.params.id).then(function (data) {
          if (data) {
            _this.grade = data.items[0];
          }
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.getData = false;
        });
      }
    },
    handleChange: function handleChange(e) {
      this.grade.name = Encoding.toHankakuCase(e.target.value).trim();
    },
    saveData: function saveData() {
      var _this2 = this;

      var valid = true;
      this.error = {
        name: null
      };

      if (!this.grade.name) {
        this.error.name = "学年を入力してください。";
        valid = false;
      }

      if (this.grades.find(function (v) {
        return _this2.$route.params.id !== v.id && v.name === _this2.grade.name;
      })) {
        this.error.name = "既存の学年と重複しています。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      this.grade.name = Encoding.toHankakuCase(this.grade.name).trim();

      if (this.$route.params.id) {
        this.loadingMessage = "学年データを更新しています。";
        this.isLoading.postData = true;
        updateData("writer/write_grade/" + this.$route.params.id, this.grade).then(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "学年更新が完了しました",
            title: "更新完了"
          });

          _this2.showModal = true;
        }).catch(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "更新に失敗しました。",
            title: "エラー"
          });

          _this2.showModal = true;
        }).then(function () {
          _this2.showModal = true;
          _this2.isLoading.postData = false;
        });
      } else {
        this.loadingMessage = "学年データを登録しています。";
        this.isLoading.postData = true;
        createData("writer/write_grade", this.grade).then(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "学年登録が完了しました",
            title: "登録完了"
          });

          _this2.showModal = true;
        }).catch(function () {
          _this2.$store.dispatch("commitModalMessage", {
            message: "作成に失敗しました。",
            title: "エラー"
          });

          _this2.showModal = true;
        }).then(function () {
          _this2.showModal = true;
          _this2.isLoading.postData = false;
        });
      }
    },
    removeData: function removeData() {
      var _this3 = this;

      this.loadingMessage = "学年データを削除しています。";
      this.isLoading.postData = true;
      deleteData("eraser/delete_grade/" + this.$route.params.id).then(function () {
        _this3.$store.dispatch("commitModalMessage", {
          message: "学年を削除しました",
          title: "削除完了"
        });
      }).catch(function (error) {
        //APIレスポンスのエラーメッセージの構造に癖がある
        var message = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[1].join(",") : "";

        _this3.$store.dispatch("commitModalMessage", {
          message: "学年を削除できませんでした。" + message,
          title: "エラー"
        });
      }).then(function () {
        _this3.showModal = true;
        _this3.isLoading.postData = false;
      });
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "grades"
      });
    }
  }
};