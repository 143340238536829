import { computed } from "vue";
import { useStore } from "vue2-helpers/vuex";
import MonthlyDekitus from "@/components/dekitus/monthly/MonthlyDekitus.vue";
export default {
  __name: 'Monthly',
  setup: function setup(__props) {
    // @ts-check
    var store = useStore();
    var studentAddress = computed(function () {
      return store.state.studentAddress;
    });
    return {
      __sfc: true,
      store: store,
      studentAddress: studentAddress,
      MonthlyDekitus: MonthlyDekitus
    };
  }
};