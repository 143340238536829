import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main"
  }, [_setup.isLoading ? _c(_setup.LoadingMessage) : _vm._e(), _c('div', {
    staticClass: "page-header"
  }, [_c('h1', {
    staticClass: "page-title"
  }, [_vm._v("学習支援ツール利用者一覧")]), _c('div', {
    staticClass: "button_wrap"
  }, [_c('div', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "item switch_button__left",
    attrs: {
      "to": {
        name: 'attendances'
      }
    }
  }, [_vm._v(" 欠席履歴 ")]), _c('router-link', {
    staticClass: "item switch_button__right active",
    attrs: {
      "to": {
        name: 'dekitus',
        params: {
          id: 1
        }
      },
      "aria-current": "page"
    }
  }, [_vm._v(" 学習履歴 ")])], 1)])]), _setup.dekitusStudents.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_setup.dekitusStudents, function (dekitusStudent, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(dekitusStudent.dekitus_id))]), _c('td', [_vm._v(" " + _vm._s(dekitusStudent.student.grade && dekitusStudent.student.grade.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(dekitusStudent.student.classroom && dekitusStudent.student.classroom.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(dekitusStudent.student.grade && dekitusStudent.student.name) + " ")]), _c('td', {
      staticClass: "links"
    }, [_c('router-link', {
      staticClass: "button-show",
      attrs: {
        "to": {
          name: 'dekitus.detail',
          query: {
            student_id: dekitusStudent.student.id
          }
        }
      }
    }, [_vm._v(" 学習履歴を見る ")]), _c('router-link', {
      staticClass: "button-calendar",
      attrs: {
        "to": {
          name: 'dekitus.monthly',
          query: {
            classroom_id: dekitusStudent.student.classroom && dekitusStudent.student.classroom.id
          }
        }
      }
    }, [_vm._v(" カレンダー ")])], 1)]);
  }), 0)]) : _c(_setup.DekitusIntroduction)], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("学年")]), _c('th', [_vm._v("クラス")]), _c('th', [_vm._v("氏名")]), _c('th')])]);
}];
export { render, staticRenderFns };