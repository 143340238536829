import _objectSpread from "/home/soutaito/projects/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import moment from "moment";
import { getData } from "@/services/axios";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "EnqueteIndex",
  components: {
    RowItem: RowItem
  },
  data: function data() {
    return {
      fiscalYear: moment().add(-3, "month").year(),
      isLoading: {
        enquete: true
      },
      filterOptions: {
        keyword: "",
        date: ""
      },
      enquetes: [],
      filteredEnquetes: []
    };
  },
  computed: _objectSpread({}, mapState(["fiscalYears"])),
  created: function created() {
    this.setData();
  },
  methods: {
    isPastList: function isPastList(enquete) {
      return moment(enquete.implement_to).add(-3, "month").year() < moment().add(-3, "month").year();
    },
    setData: function setData() {
      var _this = this;

      getData(["reader/get_enquete_list"]).then(function (data) {
        _this.enquetes = data[0].items.map(function (v) {
          var answerRate = v.answered === 0 || v.targets === 0 ? 0 : Math.round(v.answered / v.targets * 1000) / 10;
          return {
            id: v.id,
            subject: v.subject,
            answered: v.answered,
            targets: v.targets,
            answer_rate: answerRate,
            created_at: v.created_at,
            implement_to: v.implement_to,
            teacher_name: v.teacher_name,
            visibility: v.visibility,
            is_visible: v.is_visible
          };
        });
        _this.filteredEnquetes = _this.enquetes.filter(function (v) {
          return moment(v.implement_to) > moment(Number(_this.fiscalYear) + "-04-01") && moment(v.implement_to) < moment(Number(_this.fiscalYear) + 1 + "-04-01");
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.enquete = false;
      });
    },
    filterList: function filterList() {
      var _this2 = this;

      var enquetes = this.enquetes;

      if (this.filterOptions.date) {
        enquetes = enquetes.filter(function (v) {
          return moment(v.implement_to).startOf("day").isSame(moment(_this2.filterOptions.date).startOf("day"));
        });
      }

      if (this.filterOptions.keyword) {
        enquetes = enquetes.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(_this2.filterOptions.keyword) > -1;
          });
        });
      }

      enquetes = enquetes.filter(function (v) {
        return moment(v.implement_to) > moment(Number(_this2.fiscalYear) + "-04-01") && moment(v.implement_to) < moment(Number(_this2.fiscalYear) + 1 + "-04-01");
      });
      this.filteredEnquetes = enquetes;
    },
    formatDate: function formatDate(date) {
      return moment(date).format("YYYY年MM月DD日 HH:mm");
    }
  }
};